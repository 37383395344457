import React, { useState, useEffect } from "react";
import data from "../constant/indicatorview.json";
import TextComponent from "../TextComponent";
import BarChartComponent from "../BarChartComponent";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
const IndicatorView = () => {
  const [tableData, setTableData] = useState([]);
  const [restData,setRestData]=useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAge, setSelectedAge] = useState(localStorage.getItem("ageGroup"));
  console.log(tableData)

  const [open, setOpen] = useState(false);
  const itemsPerPage = 8;
  const handleAgeChange = (event) => {
    setSelectedAge(event);
    setCurrentPage(1);
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/indicatorall`);
        const jsonData = await response.json();
        setTableData(jsonData.indicators);
        let obj={
          ageIndicators:jsonData.ageIndicators,
          totalIndicators:jsonData.indicatorsCount
        }
        setRestData(obj)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on mount
  const filteredData =
    selectedAge === "All"
      ? tableData
      : tableData.filter((item) => item.Indicator_Age === selectedAge);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  // console.log(filteredData, "table", selectedAge);

  return tableData.length !== 0 ? (
    <div className="flex flex-col mx-auto flex-wrap">
      <div className="flex justify-between m-8">
        <div>
          <h2 className="text-base text-neutral-800 font-bold">
            Indicator Overview
          </h2>
        </div>
        <div>
          <div
            className="cursor-pointer  w-full py-[6px] pl-3 pr-5 bg-[#FFF3DE] rounded relative"
            onClick={() => setOpen(!open)}
          >
            <div className="text-xs font-normal flex items-center text-[#3E4149]">
              Age Group:{" "}
              <span className="text-xs font-semibold text-[#FF8A00] mr-1 ml-2">
                {selectedAge !== "All" ? selectedAge.slice(4) : selectedAge}
              </span>
              <span className={`${open ? "" : "transform rotate-180"}`}>
                &#x25BE;
              </span>
            </div>
          </div>
          {open && (
            <div className="bg-[#FFF3DE] rounded max-w-[150px] w-[150px] absolute top-36 z-10 right-8">
              {["All", ...Array(9)].map((item, index) => (
                <div
                  className={`py-[1px] px-[2px] w-[150px] m-auto text-center ${
                    [...Array(9)].length - 1 === index
                      ? ""
                      : "border-b border-[#DBDBDB]"
                  }`}
                  key={`list${index}_${Math.random()}`}
                >
                  <p
                    onClick={() =>
                      handleAgeChange(
                        item === "All"
                          ? "All"
                          : `in | ${6 + index-1}-${7 + index-1} yrs`
                      )
                    }
                    className={`cursor-pointer h-7 w-full pl-4 inline-flex items-center ${
                      selectedAge ===
                      (item === "All"
                        ? "All"
                        : `in | ${6 + index-1}-${7 + index-1} yrs`)
                        ? "bg-[#FF8A00] text-xs text-white rounded"
                        : "text-[#737373] text-xs"
                    }`}
                  >
                    {item === "All" ? "All" : `${6 + index-1}-${7 + index-1} yrs`}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {selectedAge ? (
        <div>
          <div className="flex justify-between items-center w-full">
            <div className="w-[47%] ml-6">
              <TextComponent data={data[0]} val={{ "Total Indicators": "20k" }} />
            </div>
            <div className="w-[47%] mr-6">
              <TextComponent data={data[1]} val={{ "Total Users": "10k" }} />
            </div>
          </div>
          <div className="w-11/12 mx-auto">
            <BarChartComponent data={data[2]} height={350} val={restData.ageIndicators} />
          </div>
          <div className="w-full">
            <DynamicTable data={currentPageData} clickableFields={[]} hiddenFields={["indicatorKey"]}  />
          </div>
          <div className="flex overflow-x-auto sm:justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default IndicatorView;
