import React from 'react'
import SearchBoxIcon from './SearchBoxIcon'

const SearchBox = () => {
    return (
        <div className='rounded  flex items-center relative w-2/6'>
            <input placeholder='Search for name or phone number' className=' bg-[#F6F6FB] py-2 pl-4 border-none outline-none text-xs text-[#1F384C]  w-full rounded' />
            <SearchBoxIcon className="absolute right-2 top-2" />
        </div>
    )
}

export default SearchBox