import React from "react";
import ReactApexChart from "react-apexcharts";

const HeatmapChart = ({data}) => {
  console.log(data)
  const categories = data.map((item) => item.name);
  if (!data) {
    return <div>No data available</div>;
  }
  const ranges = [-0.8, -0.4, 0, 0.4, 0.8];

  const yAxisKeys = Object.keys(data[0]).filter((key) => key !== "name");

  const series = yAxisKeys.map((key) => ({
    name: key,
    data: data.map((item) => (item[key] !== undefined ? item[key].value : 0)),
  }));
  const labelFormatter = (value) => {
    // Define the maximum length for the label
    const maxLength = 10;
    // Check if the label length exceeds the maximum length
    if (value.length > maxLength) {
      // Truncate the label and add ellipsis
      return value.substring(0, maxLength) + '...';
    } else {
      return value; // Return the original label
    }
  };

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            // Shades of Red (negative values)
            {
              from: -1,
              to: -0.9,
              color: "#6e1218",
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.9,
              to: -0.8,
              color: "#6e1218",
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.8,
              to: -0.7,
              color: "#6e1218",
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.7,
              to: -0.6,
              color: "#ae3037",
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.6,
              to: -0.5,
              color: "#cf474f",
              textColor: ["#848486"],
              opacity: 1
            },

            // Shades of Green (positive values)
            {
              from: -0.5,
              to: -0.4,
              color: "#F4CFD3", // Light Green
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.4,
              to: -0.3,
              color: "#F4CFD3", // Light Green
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.3,
              to: -0.2,
              color: "#F9EDEF", // Light Green
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: -0.2,
              to: 0.0,
              color: "#F9EDEF", // Light Green
              textColor: ["#848486"],
              opacity: 1
            },
            {
              from: 0.0,
              to: 0.1,
              color: "#E4F0EA",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.1,
              to: 0.2,
              color: "#E4F0EA",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.2,
              to: 0.3,
              color: "#D5E6DC",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.3,
              to: 0.4,
              color: "#D5E6DC",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.4,
              to: 0.5,
              color: "#BED8C7",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.5,
              to: 0.6,
              color: "#BED8C7",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.6,
              to: 0.7,
              color: "#BED8C7",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.7,
              to: 0.8,
              color: "#99C3A6",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.8,
              to: 0.9,
              color: "#99C3A6",
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
            {
              from: 0.9,
              to: 1,
              color: "#99C3A6", // Dark Green
              textColor: ["#848486"],
              opacity: 1 // Removed opacity
            },
          ],

        },

      },

    },
    legend: {
      show: false, // Set to false to hide the legend
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#848486'],
        fontSize:"12px",
        fontWeight: 'normal'
      }
    },
    xaxis: {
      type: "category",
      categories: categories,
      tooltip: {
        enabled: false
      },
      // .map(label => (label.length > 10 ? label.substring(0, 10) + '...' : label))
      labels: {
        show: true,
        style: {
          colors: ['#464646'], // Set text color for x-axis labels (black)
          fontSize:"10px",
          fontWeight:"normal",
          // rotate: 90,
        },
        // formatter: labelFormatter
      },
    },
    tooltip: {
      style: {
        fontSize: "10px",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
      formatter:  labelFormatter,
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const indicatorValue = w.globals.series[seriesIndex][dataPointIndex];
        const xAxisCategory = w.globals.labels[dataPointIndex];
        const yAxisKey = yAxisKeys[seriesIndex];
        const fullXAxisLabel = categories[dataPointIndex];
        // Get the full label text
        return `<div style="font-size:13px;" class="custom-tooltip">
                  <span>${fullXAxisLabel}</span>
                  <br />
                  <span>Correlated_To: ${yAxisKey}</span>
                  <br />
                  <span>Value: ${indicatorValue}</span>
                </div>`;
      },
    }, 
    yaxis: {
      type: "category",
      categories: yAxisKeys,
      labels: {
        show: true,
        style: {
          colors: ['#464646'],
          fontSize:"12px",
          fontWeight:"normal",
          // formatter: (value, index) => {
          //   // Determine the middle value index based on the data length
          //   const middleIndex = Math.floor(yAxisKeys.length / 2);
          //   // Show label only for the middle value
          //   if (index === middleIndex) {
          //     return value;
          //   } else {
          //     return "";
          //   }
          // },
          // Set text color for x-axis labels (black)
        },
      },
    },
  };

  return (
    <div className="flex justify-center items-center space-x-2 h-full scrollbar-thin">
      <ReactApexChart
        options={options}
        series={series}
        type="heatmap"
        height={500}
        width={1000}
      />
      <div className="flex flex-col relative mb-32">
        <div className="w-3 h-8 bg-gradient-to-b from-[#c1464e] to-[#F1BEC3]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#F1BEC3] to-[#F4CFD3]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#F4CFD3] to-[#F9EDEF]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#F9EDEF] to-[#E4F0EA]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#E4F0EA] to-[#D5E6DC]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#D5E6DC] to-[#BED8C7]"></div>
        <div className="w-3 h-8 bg-gradient-to-b from-[#BED8C7] to-[#99C3A6]"></div>

      </div>
      <div className="h-full flex space-y-4 flex-col mb-32">{ranges.map((range, index) => (<p className="text-lg text-[#464646] font-normal  ">{range}</p>))}</div>

    </div>
  );
};

export default HeatmapChart;