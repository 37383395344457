const LineProgressBar = ({
  current,
  total,
  clsNameInprogress,
  clsNameOutprogress
}) => {
  const progress = (current / total) * 100

  return (
    <div className={clsNameOutprogress}>
      <div
        className={`progress ${clsNameInprogress} animate-progressAnimation`}
        style={{ width: `${progress + '%' || '40%'}` }}></div>
    </div>
  )
}

export default LineProgressBar
