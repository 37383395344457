import valdata from "./constant/val.json";

const TextComponent = ({ data, val }) => {
  // console.log(Object.entries(valdata[data.data[0].key]))
  console.log(Object.entries(val));
  return (
    <div className={`max-lg:w-full px-2 mb-4 space-y-5 bg-white`}>
      {Object.entries(val).map(([key, value]) => (
        <div key={key} className="flex items-center rounded-2xl p-4 shadow-md">
          {Object.keys(data.data[0]?.icon).length !== 0 && (
            <div className="">
              {/* Updated this part to use an image tag */}
              <img
                src={data.data[0].icon[key]}
                alt={key}
                className="rounded-md w-12 h-12"
              />
            </div>
          )}
          <div className="ml-4">
            <p className="text-zinc-950 text-[34px] font-bold font-['Poppins'] leading-[42px]">
              {value.toString().replace(/,/g, "")}
            </p>
            <p className="text-sm font-medium text-gray-500">{key}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextComponent;
