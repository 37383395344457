import React from "react";

function SideBar() {
  const navigation = [
    {
      icon: "/icon/dashboard.png",
      text: "Dashboard",
    },
    {
      icon: "/icon/document.png",
      text: "Content Management",
    },
    {
      icon: "/icon/document.png",
      text: "User Onboarding",
    },
    {
      icon: "/icon/document.png",
      text: "User Interaction",
    },
    {
      icon: "/icon/document.png",
      text: "User Analytics",
    },
    {
      icon: "/icon/document.png",
      text: "User Documentation",
    },
    {
      icon: "/icon/document.png",
      text: "Product Documentation",
    },
    {
      icon: "/icon/document.png",
      text: "Staff Management",
    },
    {
      icon: "/icon/analytics.png",
      text: "Data Analytics",
    },
  ];
  const others=[
    {
        icon:"/icon/Profile.png",
        text:"Profile"
    },
    {
        icon:"/icon/Setting.png",
        text:"Setting"
    },
    {
        icon:"/icon/info.png",
        text:"Help"
    },
  ]
  return (
    <div className="w-60">
      <div className="w-52 flex justify-between pt-6 items-center ml-3">
        <img src="/images/parentoflogo.jpg" alt="" className="w-16" />
        <p className="text-zinc-950 text-xs font-normal  ml-2">
          A Revolutionary Skill Development Platform
        </p>
      </div>
      <div className="opacity-50 text-zinc-950 text-[11px] font-normal  leading-[11px] tracking-wide mt-12 ml-10">
        MENU
      </div>
      <div className="mt-9">
        {navigation.map((el, index) => (
          <div key={index} className="flex items-center mt-5 ml-9">
            <img src={el.icon} alt={el.text} className="w-6 h-6 mr-3" />
            <h1 className="text-orange-400 text-xs font-medium leading-3 tracking-wide">
              {el.text}
            </h1>
          </div>
        ))}
      </div>
      <div className="opacity-50 text-zinc-950 text-[11px] font-normal  leading-[11px] tracking-wide mt-72 ml-10">OTHERS</div>
      <div>
      {others.map((el, index) => (
          <div key={index} className="flex items-center mt-5 ml-9">
            <img src={el.icon} alt={el.text} className="w-6 h-6 mr-3" />
            <h1 className="opacity-60 text-gray-500 text-xs font-normal  leading-3 tracking-wide">
              {el.text}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
