import React, { useEffect, useState } from "react";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
import DropDownComponent from "../components/DropDownComponent";
import SearchBoxIcon from "../components/SearchBoxIcon";
import Breadcrumb from "../components/Breadcrumb";


function ClusterTable() {
  const [tableData, setTableData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAge, setSelectedAge] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [prev, setprev] = useState("");
  const [open, setOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState(null);
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [searchActive, setSearchActive] = useState(true);
  const [activestate, setactive] = useState("");
  const [activesubstate, setactivesub] = useState("");
  const [searchFilter, setSearchFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [currentBtn, setCurrentBtn] = useState('default')
  const listOfDropdown = [
    { label: "All", subOptions: ["All"] },
    // {
    //   label: "meta",
    //   subOptions: ["All", ...(filterOptions?.meta || [])],
    // },
    { label: "countary", subOptions: ["All", ...(filterOptions?.countary || [])] },
    { label: "childAge", subOptions: ["All", ...(filterOptions?.childAge || [])] },
    { label: "problemArea", subOptions: ["All", ...(filterOptions?.problemArea || [])] },
    { label: "problemAreaType", subOptions: ["All", ...(filterOptions?.problemAreaType || [])] },
    { label: "Cluster", subOptions: ["All", ...(filterOptions?.Cluster || [])] },
    // { label: "skillName", subOptions: ["All", ...(filterOptions?.skillName || [])] },
    // { label: "indicatorName", subOptions: ["All", ...(filterOptions?.indicatorName || [])] },
  ];
  const itemsPerPage = 25;
  // console.log(tableData)
  const handleAgeChange = (event) => {
    setSelectedAge(event);
    setCurrentPage(1);
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      if (searchQuery !== "") {
        handleSearch();
        return;
      }
      const formData = new URLSearchParams();
      formData.append("page", currentPage);
      const response = await fetch(`${API_URL}/api/cluster/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const jsonData = await response.json();
      setTableData(jsonData.cluster);
      setTotalUsers(jsonData.totalCount);
      setFilterOptions(...jsonData.distinct);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, [])
  useEffect(() => {
    if ((!searchFilter && currentBtn === "default") || (currentBtn === "filter" && activestate == "All" && activesubstate == "All") || (currentBtn === "search" && searchQuery == "")) {
      fetchData();
    }
  }, [currentPage, searchFilter, currentBtn, activestate, activesubstate]);
  useEffect(() => {
    if (activestate || activesubstate) {
      // fetchData();
      if (!searchActive && searchQuery == '' && currentBtn == "filter") {
        handleFilterapi();
      }
    }
  }, [activestate, activesubstate, currentPage, currentBtn, searchActive])
  useEffect(() => {
    if (currentPage && searchActive && searchQuery !== "" && currentBtn === "search") {
      handleSearch()
    }
  }, [currentPage, searchActive, currentBtn])
  const handleActiveSubarrayChange = async (active, newActiveSubarray) => {
    if (active && newActiveSubarray) {
      setactive(active);
      setactivesub(prevState => newActiveSubarray);
    }
    setIsSearchBtn(false);
    setSearchActive(false)
    setSearchFilter(true);
    setCurrentBtn(() => 'filter');

    // handleFilterapi();
    setSearchQuery('')
    // Check if both active and newActiveSubarray are defined and not "All"
  };
  const handleFilterapi = async () => {
    setIsLoading(true);
    if (
      activestate &&
      activesubstate &&
      activestate !== "All" &&
      activesubstate !== "All"
    ) {
      if (activesubstate !== prev) {
        setprev(activesubstate);
        setCurrentPage(1);
      }
      let obj = {};
      if (activestate === "skillName") {
        obj["skills.name"] = activesubstate
      }
      else if (activestate === "indicatorName") {
        obj["indicators.name"] = activesubstate
      } else {
        obj[activestate] = activesubstate;
      }
      // const formData = new URLSearchParams();
      // formData.append("limit", currentPage);
      // formData.append("obj", JSON.stringify(obj));
      const formData = {
        "skip": currentPage,
        "limit": 25,
        obj: obj
      }
      // console.log(formData, "formdata")
      const response = await fetch(`${API_URL}/api/cluster/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const jsonData = await response.json();
      // console.log(jsonData, "jsonData")
      setTableData(() => jsonData.formattedReports);
      setTotalUsers(() => jsonData.totalUsers);
      setIsLoading(false)
    } else {
      // Reset pagination and fetch all users
      setCurrentPage(1);
      setIsLoading(false)
      setprev("");
      fetchData();
    }
  }
  const handleSearch = async () => {
    try {
      setIsLoading(true)
      setIsSearchBtn(!isSearchBtn)
      setSearchActive(true)
      if (searchQuery !== "") {
        setCurrentBtn(() => 'search');
        setSearchFilter(() => true);
      } else {
        setactive('All');
        setactivesub('All')
      }
      const isSearchQueryEmpty = searchQuery === "";
      if (isSearchQueryEmpty) {
        setCurrentPage(1); // Set currentPage to 1 when search query is empty
        setprev("");
      }

      const formData = new URLSearchParams();
      if (searchQuery !== prev) {
        setCurrentPage(1); // Set currentPage to 1 when the search query changes
        setprev(searchQuery); // Update previousSearch with the current search query
      }
      formData.append("page", currentPage);
      const response = await fetch(
        `${API_URL}/api/cluster/search?q=${searchQuery}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData,
        }
      );
      const jsonData = await response.json();
      setTableData(jsonData.formattedReports);
      setTotalUsers(jsonData.totalUsers);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const totalPages = Math.ceil(totalUsers / itemsPerPage);
  const breadcrumbLinks = {
    rawTables: { label: "Raw Tables", color: "grey", link: "/alltables" },
    clusterTable: {
      label: "Cluster Table",
      color: "black",
      link: "/clustertable"
    }
  }

  return (
    <div>
      <div className="mt-4">
        <Breadcrumb
          breadcrumbLinks={breadcrumbLinks}
          currentPage="clusterTable"
        />
      </div>
      <div className="text-slate-700 font-medium text-base mx-4 mt-2"><span>Cluster Table</span><span className="text-slate-800 text-lg font-bold"></span></div>
      {tableData.length !== 0 ? (
        <div>
          <div className="flex flex-col bg-white shadow-md rounded-md m-4">
            <p className="pt-4 pb-3 text-[#5F6679] text-sm font-semibold ml-4">
              What are you looking for?
            </p>
            <div className="flex items-center justify-start mb-5 ml-4">
              <div className="rounded  flex items-center relative w-2/6">
                <input
                  placeholder="Search for childIndAvgScore..."
                  className=" bg-[#F6F6FB] py-2 pl-4 border-none outline-none text-xs text-[#1F384C]  w-full rounded"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onMouseEnter={() => setShowLabel(true)}
                  onMouseLeave={() => setShowLabel(false)}
                />
                <SearchBoxIcon className="absolute right-2 top-2" />
                {(showLabel && searchQuery == '') && (
                  <label className="absolute left-4 top-10 text-white pl-4 py-2 font-normal text-xs pointer-events-none transition-opacity opacity-100 leading-2 w-fit rounded-md bg-black text-clip">
                    Search for childIndAvgScore, childKsaAvgScore,skillsname, skillskey,indicatorkey
                  </label>
                )}
              </div>
              <div className="flex  ml-8">
                <DropDownComponent
                  Heading="Filter By"
                  options={listOfDropdown}
                  searchBtn={isSearchBtn}
                  onActiveSubarrayChange={handleActiveSubarrayChange} // Pass the callback function
                />
                <button
                  className="ml-4 bg-[#F09933] text-white rounded py-[6px] pl-6 pr-7 text-xs font-medium h-8"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {!isLoading ?
            <>
              <div>
                <DynamicTable data={tableData} />
              </div>
              <div className="flex overflow-x-auto sm:justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </> : <Loader />}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ClusterTable;
