import React, { useEffect, useState } from "react";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
import SearchBoxIcon from "../components/SearchBoxIcon";
import DropDownComponent from "../components/DropDownComponent";
import Breadcrumb from "../components/Breadcrumb";

function IndicatorAgeCmp() {
  const [tableData, setTableData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAge, setSelectedAge] = useState("All");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [prev, setprev] = useState("");
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [searchActive, setSearchActive] = useState(true);
  const [activestate, setactive] = useState("");
  const [activesubstate, setactivesub] = useState("");
  const [searchFilter, setSearchFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [currentBtn, setCurrentBtn] = useState("default");
  const itemsPerPage = 8;
  const taxonomyFamilesArr = [
    "All",
    "Origination",
    "Adaptation",
    "Complex Overt Response",
    "Mechanism",
    "Guided Response",
    "Perception",
    "Evaluating",
    "Synthesizing",
    "Analysing",
    "Applying",
    "Applying",
    "Understanding",
    "Remembering",
  ];
  const ageArr = [
    "All",
    "0-3 months",
    "3-6 months",
    "6-9 months",
    "9-12 months",
    "1-1.5 yrs",
    "1.5-2 yrs",
    "2-2.5 yrs",
    "2.5-3 yrs",
    "3-4 yrs",
    "4-5 yrs",
    "5-6 yrs",
    "6-7 yrs",
    "7-8 yrs",
    "8-9 yrs",
    "9-10 yrs",
    "10-11 yrs",
    "11-12 yrs",
    "12-13 yrs",
    "13-14 yrs",
    "14-15 yrs",
    "15-16 yrs",
  ];
  const listOfDropdown = [
    { label: "All", subOptions: ["All"] },
    { label: "age", subOptions: ageArr },
    { label: "taxonomyFamily", subOptions: taxonomyFamilesArr },
  ];
  const handleAgeChange = (event) => {
    setSelectedAge(event);
    setCurrentPage(1);
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fetchData = async () => {
    setIsLoading(true);
    setCurrentBtn(() => "default");
    setSearchFilter(() => false);
    try {
      if (searchQuery !== "") {
        handleSearch();
        return;
      }
      if (activestate && activesubstate && activestate !== "All" && activesubstate !== "All") {
        handleActiveSubarrayChange();
        return
      }
      const formData = new URLSearchParams();
      formData.append("page", currentPage);
      const response = await fetch(`${API_URL}/api/indicatorAge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const jsonData = await response.json();
      setTableData(jsonData.resultData);
      setTotalUsers(jsonData.totalCount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (
      (!searchFilter && currentBtn === "default") ||
      (currentBtn === "filter" &&
        activestate == "All" &&
        activesubstate == "All") ||
      (currentBtn === "search" && searchQuery == "")
    ) {
      fetchData();
    }
  }, [currentPage, searchFilter, currentBtn, activestate, activesubstate]);
  useEffect(() => {
    if (activestate || activesubstate) {
      // fetchData();
      if (!searchActive && searchQuery == "" && currentBtn == "filter") {
        handleFilterapi();
      }
    }
  }, [activestate, activesubstate, currentPage, currentBtn, searchActive]);
  useEffect(() => {
    if (
      currentPage &&
      searchActive &&
      searchQuery !== "" &&
      currentBtn === "search"
    ) {
      handleSearch();
    }
  }, [currentPage, searchActive, currentBtn]);
  const handleActiveSubarrayChange = async (active, newActiveSubarray) => {
    if (active && newActiveSubarray) {
      setactive(active);
      setactivesub((prevState) => newActiveSubarray);
    }
    setIsSearchBtn(false);
    setSearchActive(false);
    setSearchFilter(true);
    setCurrentBtn(() => "filter");

    // handleFilterapi();
    setSearchQuery("");
    // Check if both active and newActiveSubarray are defined and not "All"
  };
  const handleFilterapi = async () => {
    setIsLoading(true);
    if (
      activestate &&
      activesubstate &&
      activestate !== "All" &&
      activesubstate !== "All"
    ) {
      if (activesubstate !== prev) {
        setprev(activesubstate);
        setCurrentPage(1);
      }

      const formData = {
        query: {
          [activestate]: activesubstate,
        },
        page: currentPage,
      };
      // console.log(formData, "formdata")
      const response = await fetch(`${API_URL}/api/indicatorage/filter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const jsonData = await response.json();
      // console.log(jsonData, "jsonData")
      setTableData(() => jsonData.data);
      setTotalUsers(() => jsonData.totalItems);
      setIsLoading(false);
    } else {
      // Reset pagination and fetch all users
      setCurrentPage(1);
      setIsLoading(false);
      setprev("");
      fetchData();
    }
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      setIsSearchBtn(!isSearchBtn);
      setSearchActive(() => true);
      if (searchQuery !== "") {
        setCurrentBtn(() => "search");
        setSearchFilter(() => true);
      } else {
        setactive("All");
        setactivesub("All");
      }
      const isSearchQueryEmpty = searchQuery === "";
      if (isSearchQueryEmpty) {
        setCurrentPage(1); // Set currentPage to 1 when search query is empty
        setprev("");
      }

      if (searchQuery !== prev) {
        setCurrentPage(1); // Set currentPage to 1 when the search query changes
        setprev(searchQuery); // Update previousSearch with the current search query
      }
      const formData = {
        searchQuery: searchQuery,
        page: currentPage,
      };
      try {
        const response = await fetch(`${API_URL}/api/indicatorage/search`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const jsonData = await response.json();
        setTableData(jsonData.output);
        setTotalUsers(jsonData.totalUsers);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const totalPages = Math.ceil(totalUsers / itemsPerPage);
  console.log(totalUsers);
  console.log(currentPage, tableData);
  const breadcrumbLinks = {
    rawTables: { label: "Raw Tables", color: "grey", link: "/alltables" },
    indAgeTable: {
      label: "Indicator Age Table",
      color: "black",
      link: "/indicatorAge"
    }
  }
  return (
    <div>
      <div className="mt-4">
        <Breadcrumb
          breadcrumbLinks={breadcrumbLinks}
          currentPage="indAgeTable"
        />
      </div>
      <div className="text-slate-700 font-medium text-base mx-4 mt-2"><span>Indicator Age Table</span><span className="text-slate-800 text-lg font-bold"></span></div>
      {tableData.length !== 0 ? (
        <div>
          <div className="flex flex-col bg-white shadow-md rounded-md m-4 pl-2">
            <p className="pt-4 pb-3 text-[#5F6679] text-sm font-semibold">
              What are you looking for?
            </p>
            <div className="flex items-center justify-start mb-5">
              <div className="rounded  flex items-center relative w-2/6">
                <input
                  placeholder="Search for name or phone number"
                  className=" bg-[#F6F6FB] py-2 pl-4 border-none outline-none text-xs text-[#1F384C]  w-full rounded"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                // onMouseEnter={() => setShowLabel(true)}
                // onMouseLeave={() => setShowLabel(false)}
                />
                <SearchBoxIcon className="absolute right-2 top-2" />
              </div>
              <div className="flex  ml-8">
                <DropDownComponent
                  Heading="Filter By"
                  options={listOfDropdown}
                  searchBtn={isSearchBtn}
                  onActiveSubarrayChange={handleActiveSubarrayChange} // Pass the callback function
                />
                <button
                  className="ml-4 bg-[#F09933] text-white rounded py-[6px] pl-6 pr-7 text-xs font-medium h-8"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {!isLoading ? (
            <>
              <div>
                <DynamicTable data={tableData} />
              </div>
              <div className="flex overflow-x-auto sm:justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default IndicatorAgeCmp;
