import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import SearchBox from "../components/SearchBox";
import DropDownComponent from "../components/DropDownComponent";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
import Breadcrumb from "../components/Breadcrumb";

function DetailInd() {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [age, setage] = useState(null);
  const [selectedAge, setSelectedAge] = useState("All");
  const [open, setOpen] = useState(false);
  const itemsPerPage = 20;
  const dataFetchedRef=useRef(false);
  const handleAgeChange = (event) => {
    setSelectedAge(event);
    setCurrentPage(1);
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log(tableData);
  const fetchData = async () => {
    try {
      const data = JSON.parse(localStorage.getItem("Cluster Name"))[0]["Cluster Name"];
      const formData = new URLSearchParams();
      formData.append("thread", data);
      console.log(data)
      const response = await fetch(`${API_URL}/api/indAvg/details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      setage(data);
      const jsonData = await response.json();
      setTableData(jsonData.indicatoritems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;   
     fetchData();
  }, []);
  const filteredData =
    selectedAge === "All"
      ? tableData
      : tableData.filter((item) => item.Indicator_Age === selectedAge);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  console.log(filteredData, "table", selectedAge);
  const breadcrumbLinks = {
    curation: { label: "Curation Engine", color: "grey", link: "/curation" },
    problemArea: { label: "Problem Areas", color: "grey", link: "/problem-area" },
    detailInd: { label: "Indicator Detailed Overview", color: "black", link: "/detail-ind" },
    // Add more breadcrumb links as needed
  };
  return (
    <div className="w-full pl-10 pr-10 mt-9 mb-7">
      <div className="ml-[-2px]">
      <Breadcrumb breadcrumbLinks={breadcrumbLinks} currentPage="detailInd" />
      </div>
      {currentPageData.length !== 0 ? (
        <div className="mt-6">
          <div>
            <div className="text-slate-700 font-medium text-base mx-4 mb-4"><span>Indicator Detailed Overview</span><span className="text-slate-800 text-lg font-bold"> for {age}</span></div>
            <DynamicTable
              data={currentPageData}
              hiddenFields={[
                "indKey",
                "Correlated_KSAs",
                "Correlated_Indicators",
                "Cluster_Number"
              ]}
              clickableFields={[
                {
                  name: "Correlated Indicators",
                  path: "ind-correlation",
                  key: "indicator_data",
                  type: "ind-ind",
                },
                {
                  name: "Correlated Skills",
                  path: "ind-correlation",
                  key: "indicator_data",
                  type: "ind-ksa",
                },
              ]}
            />
          </div>
          <div className="flex overflow-x-auto sm:justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default DetailInd;
