import React from 'react'

function TableComponent({ data }) {
  // console.log("data", data)
  return (
    <div className='block w-full'>
      {data?.name && <h3 className="px-4 text-2xl font-bold leading-2 text-gray-600 w-full">{data?.name}</h3>}
      {data?.head && <h1 className="px-4 text-2xl font-bold leading-2 text-gray-600 w-full">{data?.head}</h1>}
      <div className="relative overflow-x-auto drop-shadow-md sm:rounded-lg p-8 ">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>

            </tr>
          </thead>
          <tbody>
            {Object.entries(data.data).map(([key, value]) => (
              <tr key={key} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                  <div className="font-medium text-neutral-800">{key}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                <div className="font-bold text-neutral-700">{value}</div>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>)
}

export default TableComponent