import logo from './logo.svg';
// import './App.css';
import './index.css'
import Dashboard from './Dashboard';
import data from './constant/correlation.json'
import ClusterChart from './ClusterChart';
import DashBoardPage from './pages/dashboardpage';
import CurationEngine from './pages/curation';
import Age from './pages/age';
import PrivatePage from './pages/Private';
// https://pictogrammers.com/library/mdi/
function App() {
  return (
    <div className="App">
      <header className="text-3xl font-bold">
      <link href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.2.0/flowbite.min.css" rel="stylesheet" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.3.67/css/materialdesignicons.min.css" integrity="sha512-nRzny9w0V2Y1/APe+iEhKAwGAc+K8QYCw4vJek3zXhdn92HtKt226zHs9id8eUq+uYJKaH2gPyuLcaG/dE5c7A==" crossorigin="anonymous" referrerpolicy="no-referrer" />      </header>
        <body>
        <script src="../node_modules/flowbite/dist/flowbite.min.js"></script>
        {/* <Dashboard /> */}
        {/* <HeatmapChart data={data} /> */}
        {/* <ClusterChart data={Clusterdata} /> */}
        {/* <DashBoardPage /> */}
        {/* <CurationEngine /> */}
        {/* <Age /> */}
        <PrivatePage />
        </body>
    </div>
  );
}

export default App;
