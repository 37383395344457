import React, { useEffect, useState } from "react";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
import DropDownComponent from "../components/DropDownComponent";
import SearchBoxIcon from "../components/SearchBoxIcon";

function AllIndKsa() {
  const [filterOptions, setFilterOptions] = useState(null);

  const listOfDropdown = [
    { label: "All", subOptions: ["All"] },
    {
      label: "Ind_TaxFamily",
      subOptions: ["All", ...(filterOptions?.Ind_TaxFamily || [])],
    },
    { label: "Skill_Name", subOptions: ["All", ...(filterOptions?.Skill_Name || [])] },
    { label: "Ind_TaxCategory", subOptions: ["All", ...(filterOptions?.Ind_TaxCategory || [])] },
    { label: "Age", subOptions: ["All", ...(filterOptions?.Age || [])] },
    { label: "Address_Thread", subOptions: ["All", ...(filterOptions?.Address_Thread || [])] }
  ];
  const [tableData, setTableData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTrue, setisTrue] = useState(false);
  const [selectedAge, setSelectedAge] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [prev, setprev] = useState("");
  const [open, setOpen] = useState(false);
  const [activestate, setactive] = useState("");
  const [activesubstate, setactivesub] = useState("");
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [searchActive, setSearchActive] = useState(true);
  const [searchFilter, setSearchFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [currentBtn, setCurrentBtn] = useState('default')

  const itemsPerPage = 25;
 

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    if (currentPage && searchActive && searchQuery !== "" && currentBtn === "search") {
        handleSearch()
    }
}, [currentPage, searchActive, currentBtn])
  const fetchData = async () => {
    try {
      setIsLoading(true)
      setCurrentBtn(() => 'default');
      setSearchFilter(()=>false)
      if (searchQuery !== "") {
        handleSearch();
        return;
      }
      if (activestate && activesubstate && activestate !== "All" && activesubstate !== "All") {
        handleActiveSubarrayChange();
        return
      }
      const formData = new URLSearchParams();
      formData.append("page", currentPage);
      const response = await fetch(`${API_URL}/api/getallIndKsa`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const jsonData = await response.json();
      setTableData(jsonData.filteredData);
      setTotalUsers(jsonData.totalIndKsa);
      setFilterOptions(jsonData.distinct);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if ((!searchFilter && currentBtn === "default") || (currentBtn === "filter"  && activestate == "All" && activesubstate == "All") || (currentBtn==="search" && searchQuery=="")) {
        fetchData();
    }
}, [currentPage, searchFilter, currentBtn,activestate,activesubstate]);
useEffect(() => {
  if (activestate || activesubstate) {
      // fetchData();
      if (!searchActive && searchQuery == '' && currentBtn == "filter") {
          handleFilterapi();
      }
  }
}, [activestate, activesubstate, currentPage, currentBtn, searchActive])
  const handleActiveSubarrayChange = async (active, newActiveSubarray) => {
    if (active && newActiveSubarray) {
      setactive(active);
      setactivesub(prevState => newActiveSubarray);
    }
    setIsSearchBtn(false);
    setSearchActive(false)
    setSearchFilter(true);
    setCurrentBtn(() => 'filter');

    // handleFilterapi();
    setSearchQuery('')
    // Check if both active and newActiveSubarray are defined and not "All"
  };
  const handleFilterapi = async () => {
    setIsLoading(true)

    if (
      activestate &&
      activesubstate &&
      activestate !== "All" &&
      activesubstate !== "All"
    ) {
      if (activesubstate !== prev) {
        setprev(activesubstate);
        setCurrentPage(1);
      }
      let obj = {};
      obj[activestate] = activesubstate;
      const formData = new URLSearchParams();
      formData.append("page", currentPage);
      formData.append("query", JSON.stringify(obj));
      const response = await fetch(`${API_URL}/api/filterIndKsa`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const jsonData = await response.json();
      setTableData(() => jsonData.filteredData);
      setTotalUsers(() => jsonData.totalCount);
      setIsLoading(false)

    } else {
      // Reset pagination and fetch all users
      setCurrentPage(1);
      setprev("");
      fetchData();
      setIsLoading(false)
    }
  }
  const handleSearch = async () => {
    try {
      setIsLoading(true)
      setIsSearchBtn(!isSearchBtn)
      setSearchActive(() => true)
      if (searchQuery !== "") {
          setCurrentBtn(() => 'search');
          setSearchFilter(() => true);
      }else{
          setactive('All');
          setactivesub('All')
      }
      const isSearchQueryEmpty = searchQuery === "";
      if (isSearchQueryEmpty) {
        setCurrentPage(1); // Set currentPage to 1 when search query is empty
        setprev("");
      }

      const formData = new URLSearchParams();
      formData.append("searchQuery", searchQuery);
      if (searchQuery !== prev) {
        setCurrentPage(1); // Set currentPage to 1 when the search query changes
        setprev(searchQuery); // Update previousSearch with the current search query
      }
      formData.append("page", currentPage);
      const response = await fetch(`${API_URL}/api/searchIndksa`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      const jsonData = await response.json();
      setTableData(jsonData.filteredData);
      setTotalUsers(jsonData.totalIndKsa);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  return (
    <div>
            <div className="text-slate-700 font-medium text-base mx-4 mt-4"><span>Indicator Ksa Mapping Table</span><span className="text-slate-800 text-lg font-bold"></span></div>
      {tableData.length !== 0 ? (
        <div>
          <div className="flex flex-col bg-white shadow-md rounded-md m-4">
            <p className="pt-4 pb-3 text-[#5F6679] text-sm font-semibold ml-4">
              What are you looking for?
            </p>
            <div className="flex items-center justify-start mb-5 ml-4">
              <div className="rounded  flex items-center relative w-2/6">
                <input
                  placeholder="Search for Age..."
                  className=" bg-[#F6F6FB] py-2 pl-4 border-none outline-none text-xs text-[#1F384C]  w-full rounded"
                  value={searchQuery}
                  onChange={(e) => { setSearchQuery(e.target.value); }}
                  onMouseEnter={() => setShowLabel(true)}
                  onMouseLeave={() => setShowLabel(false)}
                />
                <SearchBoxIcon className="absolute right-2 top-2" />
                {(showLabel && searchQuery =='') && (
                  <label className="absolute left-4 top-10 text-white pl-4 py-2 font-normal text-xs pointer-events-none transition-opacity opacity-100 leading-2 w-fit rounded-md bg-black text-clip">
                    Search for Ind_TaxFamily ,Age ,Address_Thread ,Skill_Name,Ind_TaxCategory
                  </label>
                )}
              </div>
              <div className="flex  ml-8">
                <DropDownComponent
                  Heading="Filter By"
                  options={listOfDropdown}
                  searchBtn={isSearchBtn}
                  onActiveSubarrayChange={handleActiveSubarrayChange} // Pass the callback function
                />

                <button
                  className="ml-4 bg-[#F09933] text-white rounded py-[6px] pl-6 pr-7 text-xs font-medium h-8"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {!isLoading ? <>
            <div>
              <DynamicTable data={tableData} />
            </div>
            <div className="flex overflow-x-auto sm:justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </> : <Loader />}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default AllIndKsa;
