import React from 'react'

const SearchBoxIcon = (props) => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            fill="none"
            {...props}
        >
            <g
                stroke="#627B87"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                opacity={0.5}
            >
                <path
                    d="M5.913 10.826a4.913 4.913 0 1 0 0-9.826 4.913 4.913 0 0 0 0 9.826Z"
                    clipRule="evenodd"
                />
                <path d="m9.72 9.72 3.087 3.087" />
            </g>
        </svg>
    )

}

export default SearchBoxIcon