import React from "react";
import PieChartComponent from "./PieChartComponent";
import TableComponent from "./TableComponent";
import BarChartComponent from "./BarChartComponent";
import TextComponent from "./TextComponent";
import LineChartComponent from "./LineChart";

const renderCard = (card, index, array) => {
  console.log(card);
  switch (card.data[0].vtype) {
    case "card-piechart":
      // Render pie chart component
      return <PieChartComponent key={card.id} data={card} />;
    case "card-barchart":
      // Render bar chart component
      return <BarChartComponent key={card.id} data={card} />;
    case "card-text":
      // Render text component
      // Check if the current card is in the middle, and if so, render a pair of charts
      if (index === Math.floor(array.length / 2)) {
        const previousCard = array[index - 1];
        const nextCard = array[index + 1];
        if (
          previousCard &&
          (previousCard.vtype === "card-piechart" ||
            previousCard.vtype === "card-barchart") &&
          nextCard &&
          (nextCard.vtype === "card-piechart" ||
            nextCard.vtype === "card-barchart")
        ) {
          return (
            <React.Fragment key={card.id}>
              {renderCard(previousCard)}
              {renderCard(card)}
              {renderCard(nextCard)}
            </React.Fragment>
          );
        }
      }
      // Render a single card text
      return <TextComponent key={card.id} data={card} />;
    case "card-table":
      // Render table component
      return <TableComponent key={card.id} data={card} />;

    case "card-linechart":
      return <LineChartComponent key={card.id} data={card} />;
    // Add more cases for other vtypes as needed
    default:
      return null;
  }
};

const Template = (props) => {
  const masterData = props.masterData || []; // Ensure masterData is an array

  return (
    <div className="flex flex-wrap justify-evenly pb-10">
      {masterData
        .map((card, index) => renderCard(card, card.id, masterData))}
    </div>
  );
  // return (
  //     <div>
  //         <div className="p-4 ">
  //             <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">

  //                 <div className="grid grid-cols-2 gap-4">
  //                     <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
  //                         <p className="text-2xl text-gray-400 dark:text-gray-500">
  //                             <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
  //                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
  //                             </svg>
  //                         </p>
  //                     </div>
  //                     <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
  //                         <p className="text-2xl text-gray-400 dark:text-gray-500">
  //                             <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
  //                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
  //                             </svg>
  //                         </p>
  //                     </div>
  //                     <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
  //                         <p className="text-2xl text-gray-400 dark:text-gray-500">
  //                             <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
  //                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
  //                             </svg>
  //                         </p>
  //                     </div>
  //                     <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
  //                         <p className="text-2xl text-gray-400 dark:text-gray-500">
  //                             <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
  //                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
  //                             </svg>
  //                         </p>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>

  //     </div>
  // )
};

export default Template;
