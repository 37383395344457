import React from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import { useNavigate } from "react-router-dom";
function PieChartComponent(data) {
  const valueData=data.val
  console.log(valueData)
  const { vtype2 } = data.data.data[0];
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(`/${location}`);
  };
  const options = {
    chart: {
      height: data.height,
      width: vtype2 ? "50%" : "100%",
      type: "pie",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      style: {
        fontSize: "12px",
        colors: ["#000000"]
      },
    },
    labels: Object.keys(valueData),
    legend: vtype2 ? {
      show: false
    } : {
      position: data.data.data[0].legendPosition,
      horizontalAlign: "center",
      offsetY:
        data.data.data[0].legendPosition === "bottom" ? (vtype2 ? -50 : 0) : 50,
      offsetX: data.data.data[0].legendPosition === "bottom" ? 0 : -5,
      fontSize: "12px",
    },
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: (data.data.data[0].button) ? 40 : 80,
        startAngle: vtype2 ? -90 : 0,
        endAngle: vtype2 ? 90 : 360,
      },
    },
    title: {
      text: `${data.data.data[0].button ? "" : data.data.data[0].name}`,
      align: "left",
      offsetX: data.title.x,
      offsetY: data.title.y,
      floating: true,
      style: {
        fontSize: "18px",
        color: "#263238",
      },
    },
    colors: Object.values(data.data.data[0].colors),
  };

  // Define your chart data
  const series = Object.values(valueData);

  return (
    <div className={`h-full shadow-md rounded-2xl items-center px-2 relative bg-white overflow-hidden`}>
      {data.data.data[0].button && (
        <div className=" px-5 pt-5">
          <p className="text-[18px] font-bold">{data.data.data[0].name}</p>
          {data.data.data[0].text &&
            Object.keys(data.data.data[0].text).length !== 0 && (
              <p className="mt-6">{data.data.data[0].text.text}</p>
            )}
          {data.data.data[0].button &&
            Object.keys(data.data.data[0].button).length !== 0 && (
              <button onClick={()=>handleClick(data.data.data[0].navigate)} className="absolute top-5 right-6 bg-custom-orange text-white shadow-md rounded-md px-6 py-2">
                {data.data.data[0].button.text}
              </button>
            )}
        </div>
      )}
      {Object.keys(data.data.data[0].data).length !== 0 && (
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          height={data.height?data.height:300}
          // width={vtype2?300:400}
        />
      )}
    </div>
  );
}

export default PieChartComponent;
