import React from "react";
import Template from "./Template";
// import data from "./constant/master_data.json";
import SideBar from "./components/SideBar";
import Top from "./components/top";
import data from "./constant/dashboarddata.json";
// import data from "./constant/curation.json"
// import data from './constant/age.json'

const Dashboard = () => {
  console.log(data);
  return (
    <div>
      <Template masterData={data} />
    </div>
  );
};

export default Dashboard;
