import React, { useEffect, useState } from "react";
import DropdownArrow from "./DropdownArrow";

const DropDownComponent = ({ Heading, options, searchBtn=false,onActiveSubarrayChange}) => {
  const [active, setActive] = useState(options[0].label); // Default to the first option
  const [activeSubarray, setActiveSubarray] = useState(options[0].subOptions[0]); // Default to the first suboption
  const [open, setOpen] = useState(false);
  const [openSubarray, setOpenSubarray] = useState(false);
useEffect(()=>{
  if(searchBtn){
    setActive(options[0].label)
    setActiveSubarray(options[0].subOptions[0]);
   }
},[searchBtn])
  const handleOptionClick = (option) => {
    setActive(option.label);
    setActiveSubarray(option.subOptions[0]);
    setOpen(false);
    setOpenSubarray(false);
  };

  const handleSubOptionClick = (subOption) => {
    setActiveSubarray(subOption);
    setOpenSubarray(false);
    // Call onActiveSubarrayChange with the new active subarray
    onActiveSubarrayChange(active, subOption);
  };

  return (
    <>
      <div className="relative ml-3">
        <div
          className="h-8 py-[6px] bg-[#FFF3DE] pl-3 pr-5 rounded flex items-center cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <p className="text-xs font-normal flex items-center text-[#3E4149]">
            {Heading}:&nbsp;
            <span className="text-xs font-semibold text-[#FF8A00] mr-1 ml-2">{active}</span>
            <span className={`${open ? "" : "transform rotate-180"}`}>
              <DropdownArrow className="" />
            </span>
          </p>
        </div>
        {open && (
          <div className="bg-[#FFF3DE] rounded absolute w-fit scrollbar-thin">
            {options.map((option, index) => (
              <div
                key={`option${index}`}
                className={`relative py-[1px] px-[2px] m-auto text-center ${
                  index === options.length - 1 ? "" : "border-b border-[#DBDBDB]"
                }`}
              >
                <p
                  onClick={() => handleOptionClick(option)}
                  className={`h-7 w-full pl-4 inline-flex items-center  cursor-pointer pr-4 ${
                    active === option.label
                      ? "bg-[#FF8A00] text-xs text-white rounded"
                      : "text-[#737373] text-xs"
                  }`}
                >
                  {option.label}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="relative ml-3">
        <div
          className="h-8 py-[6px] bg-[#FFF3DE] pl-3 pr-5 rounded flex items-center cursor-pointer"
          onClick={() => setOpenSubarray(!openSubarray)}
        >
          <p className="text-xs font-normal flex items-center text-[#3E4149]">
            SubCategory:&nbsp;
            <span className="text-xs font-semibold text-[#FF8A00] mr-1 ml-2">{activeSubarray}</span>
            <span className={`${openSubarray ? "" : "transform rotate-180"}`}>
              <DropdownArrow className="" />
            </span>
          </p>
        </div>
        {openSubarray && (
          <SubDropDown
            subOptions={options.find((option) => option.label === active)?.subOptions || []}
            activeSubarray={activeSubarray}
            onSelect={handleSubOptionClick}
            onActiveSubarrayChange={onActiveSubarrayChange} // Pass onActiveSubarrayChange to SubDropDown
          />
        )}
      </div>
    </>
  );
};

const SubDropDown = ({ subOptions, activeSubarray, onSelect, onActiveSubarrayChange }) => {
  const handleSubOptionClick = (subOption) => {
    onSelect(subOption);
    onActiveSubarrayChange(subOption);
  };

  return (
    <div className="bg-[#FFF3DE] rounded ml-1 mt-1 absolute w-full max-h-48 overflow-y-scroll scrollbar-thin">
      {subOptions.map((subOption, index) => (
        <div
          key={`subOption${index}`}
          className={`py-[1px] px-[2px] m-auto text-center ${
            index === subOptions.length - 1 ? "" : "border-b border-[#DBDBDB]"
          } whitespace-nowrap`}
        >
          <p
            onClick={() => handleSubOptionClick(subOption)}
            className={` w-full pl-4 inline-flex items-center cursor-pointer ${
              activeSubarray === subOption
                ? "bg-[#FF8A00] text-xs text-white rounded"
                : "text-[#737373] text-xs"
            }`}
          >
            {subOption}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DropDownComponent;
