import React from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import valdata from './constant/val.json'
function LineChart(data) {
  const valueData=data.val
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    colors: Object.values(data.data.data[0].colors),
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    title: {
      text: data.data.data[0].name,
      align: "left",
      offsetY: 10,
      style: {
        fontSize: "18px",
        color: "#263238", // You can set the color according to your design
      },
    },
    grid: {
      show: false,
    },
    markers: {
      size: 1,
    },
    legend: {
      show: false,
    },
    tooltip: {
      style: {
        fontSize: "18px",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
      y: {
        formatter: function (val) {
          return val + " units";
        },
      },
    },
    xaxis: {
      categories: Object.keys(valueData),
      labels: {
        style: {
          colors: ["black", "black"],
          fontSize: "12px",
          rotate: 0,
          width: "100%",
          marginTop: "18px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
  };

  return (
    Object.keys(data.data.data[0].data).length !== 0 && (
      <div
        className={`h-full shadow-md rounded-2xl max-lg:w-full px-5 pt-5 mb-4 bg-white`}
      >
        <ReactApexChart
          options={options}
          series={[{ data: Object.values(valueData) }]}
          type="line"
          height={350}
        />
      </div>
    )
  );
}

export default LineChart;
