import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import { useNavigate } from "react-router-dom";
import valdata from "./constant/val.json";

function BarChartComponent(data) {
  const valueData = data.val;
  console.log(data.yaxisOptions, valueData);
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(`/${location}`);
  };

  const options = {
    chart: {
      height: data.height,
      dropShadow: {
        enabled: false,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      offsetY: data.y ? data.y : 25,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: Object.keys(valueData),
      labels: {
        style: {
          colors: ["black", "black"],
          fontSize: "12px",
          width: "100%",
          marginTop: "18px",
        },
        rotate: -150,
      },
    },
    yaxis: {
      min:
        data.yaxisOptions && data.yaxisOptions.min !== undefined
          ? data.yaxisOptions.min
          : undefined,
      max:
        data.yaxisOptions && data.yaxisOptions.max !== undefined
          ? data.yaxisOptions.max
          : undefined,
      tickAmount: 4, // Default to 4 ticks on the y-axis
      labels: {
        formatter: function (value) {
          return value !== (data.yaxisOptions && data.yaxisOptions.max)
            ? value
            : ""; // Hide the last label
        },
      },
      axisTicks: {
        show: true,
      },
      tickAmount: data.skipValue ? data.skipValue : 6, // Use skipValue if provided, otherwise default to 4
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.globals.labels[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];
        return (
          '<div class="custom-tooltip">' +
          `<span class="tooltip-label">${category}:</span>` +
          '<span class="tooltip-value">' +
          value +
          "</span>" +
          "</div>"
        );
      },
      fixed: {
        enabled: data.tooltip ? false : true,
        position: "bottomRight",
        offsetX: 0,
        offsetY: data.tooltip ? -30 : 0,
      },
    },
    responsive: [
      {
        breakpoint: 320,
        options: {
          legend: {
            position: "bottom",
            offsetX: 40,
            offsetY: 0,
          },
        },
      },
    ],
    colors: Object.values(data.data.data[0].colors),
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    markers: {
      size: 1,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        barHeight: "50%",
        columnWidth: "20%",
        distributed: true,
        horizontal: data.horizontal,
      },
    },
  };

  return (
    Object.keys(data.data.data[0].data).length !== 0 && (
      <div className={`px-2 mb-4 relative bg-white`}>
        <ReactApexChart
          options={{
            ...options,
            xaxis: {
              categories: Object.keys(valueData),
              labels: {
                style: {
                  colors: ["black", "black"],
                  fontSize: "12px",
                  rotate: 0,
                  width: "100%",
                  marginTop: "18px",
                },
              },
            },
          }}
          series={[{ data: Object.values(valueData) }]}
          type="bar"
          height={data.height}
        />
      </div>
    )
  );
}

export default BarChartComponent;
