import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Folder = () => {
  const navigate = useNavigate();

  const folderName = [
    { name: "Master User Table", navigate: "checkpassword" },
    { name: "Assessments Report Table", navigate: "allassessment" },
    { name: "Activity Table", navigate: "allactivity" },
    { name: "Indicator Age Table", navigate: "indicatorAge" },
    { name: "Cluster Table", navigate: "clustertable" },
    { name: "Indicator DataPoints Table", navigate: "indQuestable" },
    { name: "Indicator Ksa Table", navigate: "indKsatable" },
    { name: "Indicator Activity Table", navigate: "indactivity" },
    { name: "Drawing Assessment Report Table", navigate: "allDrawing" },
    { name: "Handwriting Question Assessment Report Table", navigate: "handwritingques" },
    { name: "Handwriting Question + Image Assessment Report Table", navigate: "handwritingimg" },
    { name: "Correlation Mining Table", navigate: "" },
  ];

  const handleClick = (path) => {
    if (!path) {
      return;
    }
    navigate(`/${path}`)
  };



  return (
    <div className="pl-10 bg-[#F8FBFF] pt-6">
      <p className="text-lg normal">
        Select the Type of Data you would like to access.
      </p>
      <div className="mt-6 flex flex-wrap w-full ">
        {folderName.map((item, index) => (
          <div
            className="pl-3 py-3 flex items-center pr-6 rounded-xl cursor-pointer bg-white shadow-md mb-6 w-[250px] mr-6"
            key={`div${index}`}
            onClick={() => handleClick(item.navigate)}
          >
            <div className="h-10 w-10 bg-[#FFF3DE] rounded-md flex items-center justify-center mr-4">
              <img alt="Image" src={"/images/folder.png"} className="h-6 w-6" />
            </div>
            <p className="text-[#5F6679] font-semibold">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Folder;
