import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5; // Change this value based on your preference

    // If there are fewer pages than maxButtonsToShow, display all buttons
    if (totalPages <= maxButtonsToShow) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            className={`px-3 py-1 mx-1 border ${
              currentPage === i ? 'bg-custom-orange text-white' : 'hover:bg-gray-200'
            }`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Display buttons with ellipsis in between for a large number of pages
      const leftEllipsis = currentPage > maxButtonsToShow - 2 && (
        <span className="mx-1 text-[#A8A7A8]  font-medium">.....</span>
      );
      const rightEllipsis =
        currentPage < totalPages - (maxButtonsToShow - 2) && <span className="mx-1 text-[#A8A7A8]  font-medium">.....</span>;

      const startPage = Math.min(
        Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2)),
        totalPages - maxButtonsToShow + 1
      );

      for (let i = startPage; i < startPage + maxButtonsToShow; i++) {
        buttons.push(
          <button
            key={i}
            className={`  font-medium mr-4 ${
              currentPage === i ? 'bg-[#F09933] text-white rounded px-2 py-px' : 'hover:bg-[#F09933] text-[#A8A7A8]'
            }`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }

      return (
        <>
          {leftEllipsis}
          {buttons}
          {rightEllipsis}
        </>
      );
    }

    return buttons;
  };
  return (
    <div className="flex justify-center items-center mt-4 ">
      <button
        className={`font-medium  mr-2.5 ${currentPage === 1 ? "text-[#A8A7A8]" :"text-[#F09933]"}`} 
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
      {`< Previous`}
      </button>
      {renderPaginationButtons()}
      <button
        className={` font-medium  ml-2.5 ${currentPage === totalPages ? "text-[#A8A7A8]" :"text-[#F09933]"}`}
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
       {`Next >`}
      </button>
    </div>
  );
};

export default Pagination;