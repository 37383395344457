import React, { useEffect, useState } from "react";
import ClusterChart from "../ClusterChart";
import Loader from "../components/Loader";
import DropdownArrow from "../components/DropdownArrow";
import { API_URL } from "../constant";
import Breadcrumb from "../components/Breadcrumb";

function ProblemArea() {
  const [selectedAge, setSelectedAge] = useState("Anger");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const listOfDropdown = [
    "Anger",
    "Complex Problem Solving",
    "Anxiety",
    "Shyness",
    "Talking To Strangers",
    "Distraction",
    "Carelessness",
    "Forgetfulness in exams",
    "Peer Group Choices",
    "Social Skills",
    "Memorization",
    "Communication Skills",
    "Creative Thinking",
    "Spellings",
    "Emotional Regulation",
    "Expressing feelings",
    "Stubbornness",
    "Poor Academics",
    "Being Gullible",
    "Developing Discipline",
    "Punctuality",
    "Junk Food Cravings",
    "Lack of focus",
    "Impatience",
    "Empathy",
    "Creative Writing",
    "Drawing",
    "Handwriting Performance"
  ];
  useEffect(() => {
    fetchData(selectedAge);
  }, [selectedAge]);

  const fetchData = async (selectedAge) => {
    try {
      const response = await fetch(`${API_URL}/api/getproblem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `problemName=${selectedAge}`,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      const organizedData = organizeData(jsonData.data);
      console.log(organizeData);
      setData(organizedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function organizeData(caseData) {
    const result = { name: "main", children: [] };

    caseData.forEach((caseEntry) => {
      const caseName = caseEntry.problemArea;
      const ageName = caseEntry.childAge;
      const addressThreadParts = caseEntry.Address_Thread.split(":");
      const childClusterId =
        addressThreadParts[addressThreadParts.length - 1].trim();

      // Find or create the case node
      let caseNode = result.children.find((child) => child.name === caseName);
      if (!caseNode) {
        caseNode = { name: caseName, children: [] };
        result.children.push(caseNode);
      }

      // Find or create the age node under the case node
      let ageNode = caseNode.children.find((child) => child.name === ageName);
      if (!ageNode) {
        ageNode = { name: ageName, children: [] };
        caseNode.children.push(ageNode);
      }

      // Find or create the cluster node under the age node
      let clusterNode = ageNode.children.find(
        (child) => child.name === childClusterId
      );
      if (!clusterNode) {
        clusterNode = { name: childClusterId, children: [] };
        ageNode.children.push(clusterNode);
      }

      // Add the data entry under the cluster node
      clusterNode.children.push(caseEntry);
    });

    return result;
  }

  const handleAgeChange = (event) => {
    setSelectedAge(event);
    setOpen(false);
  };
  const breadcrumbLinks = {
    curation: { label: "Curation Engine", color: "grey", link: "/curation" },
    problemArea: {
      label: "Problem Areas",
      color: "black",
      link: "/problem-area",
    },
    // Add more breadcrumb links as needed
  };
  return (
    <div className="ml-5">
      <div className="ml-[-5px] mt-2">
        <Breadcrumb
          breadcrumbLinks={breadcrumbLinks}
          currentPage="problemArea"
        />
      </div>
      <div className="flex justify-between mt-4 mx-4">
        <div>
          <h2 className="text-base text-neutral-800 font-bold">
            Which Area you Would Like To Explore Today ?
          </h2>
        </div>
        <div>
          <div className="relative ml-3">
            <div
              className="h-8 py-[6px] bg-[#FFF3DE] pl-3 pr-5 cursor-pointer rounded  flex items-center "
              onClick={() => setOpen(!open)}
            >
              <p className="text-xs font-normal flex items-center text-[#3E4149]">
                Problem Area:{" "}
                <span className="text-xs font-semibold text-[#FF8A00] mr-1 ml-2">
                  {selectedAge}{" "}
                </span>{" "}
                <span className={`${open ? "" : "transform rotate-180"}`}>
                  <DropdownArrow className="" />
                </span>
              </p>
            </div>
            {open && (
              <div className="bg-[#FFF3DE] rounded absolute w-full max-h-56 overflow-y-scroll scrollbar-thin">
                {listOfDropdown.map((data, index) => (
                  <div
                    className={`py-[1px] px-[2px] cursor-pointer text-center whitespace-nowrap ${
                      listOfDropdown.length - 1 == index
                        ? ""
                        : "border-b border-[#DBDBDB]"
                    }`}
                    key={`list${index}`}
                  >
                    <p
                      onClick={() => {
                        setSelectedAge(data);
                        setOpen(false);
                      }}
                      className={`h-7 w-full pl-4 inline-flex items-center ${
                        selectedAge == data
                          ? "bg-[#FF8A00] text-xs text-white rounded"
                          : "text-[#737373] text-xs"
                      }`}
                    >
                      {data}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {data ? (
        <div className="flex justify-center">
          <ClusterChart data={data} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ProblemArea;
