import React, { useState } from "react";
import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";

const geoUrl = "/features.json";

export default function MapChart({ countryData }) {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [hoveredCountryValue, setHoveredCountryValue] = useState(null);

  const handleMouseEnter = (geo, event) => {
    const mapContainer = event.currentTarget.closest(".rsm-svg");
    const rect = mapContainer.getBoundingClientRect();
    const tooltipX = event.clientX - rect.left;
    const tooltipY = event.clientY - rect.top;
    setTooltipPosition({ x: tooltipX, y: tooltipY });
    setHoveredCountry(geo);

    // Find the value of the hovered country
    const country = countryData.find((c) => c.id === geo.id);
    if (country) {
      setHoveredCountryValue(country.value);
    } else {
      setHoveredCountryValue(null);
    }
  };

  const handleMouseLeave = () => {
    setHoveredCountry(null);
    setHoveredCountryValue(null);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "85%" }}>
      <ComposableMap>
        <ZoomableGroup disablePanning={true}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const country = countryData.find((c) => c.id === geo.id);
                const fill = country ? "rgba(0, 0, 255, 0.8)" : "#D6D6DA"; // Grey color if country not found

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(event) => handleMouseEnter(geo, event)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      default: {
                        fill: fill,
                        outline: "none",
                        stroke: "#555555", // Black border color
                        strokeWidth: 0.2,
                      },
                      hover: {
                        fill: fill,
                        outline: "none",
                        stroke: "#555555", // Black border color
                        strokeWidth: 0.2,
                      },
                      pressed: {
                        fill: fill,
                        outline: "none",
                        stroke: "#555555", // Black border color
                        strokeWidth: 0.2,
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {hoveredCountry && (
        <div
          style={{
            position: "absolute",
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "5px",
            borderRadius: "5px",
            fontSize:"12px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            zIndex: 999,
          }}
        >
          <div>
            {hoveredCountry.properties.name}:{" "}
            {hoveredCountryValue ? `${hoveredCountryValue}` : "No data"}
          </div>
        </div>
      )}
    </div>
  );
}
