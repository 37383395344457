import React, { useEffect, useRef, useState } from "react";
import dashboardData from "../constant/dashboarddata.json";
import TextComponent from "../TextComponent";
import PieChartComponent from "../PieChartComponent";
import BarChartComponent from "../BarChartComponent";
import LineChart from "../LineChart";
import Loader from "../components/Loader";
import { API_URL, getDaySuffix } from "../constant";
import DonutChartComponent from "../components/DonutChart";
import WorldMapChart from "../components/WorldMap";
import MapChart from "../components/Map";
function DashBoardPage() {
  const [tabledata, setData] = useState(null);
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const formattedDate = `${day}${getDaySuffix(day)} ${month} ${year}`;
  const dataFetchedRef=useRef(false)
  // console.log(tabledata);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;   
    fetchData();
  }, []);
  const valueData = tabledata?.gender;
  const Legend = () => {
    return (
      <div className="custom-legend flex space-x-10 absolute bottom-10 left-1/2 transform -translate-x-1/2">
        {Object.keys(valueData).map((key, index) => (
          <div key={index} className="legend-item flex items-center">
            <div className="legend-color" />
            <img
              className="legend-image mr-2"
              src={`/images/${key}.png`}
              alt={`legend-${index}`}
            />
            <span className="legend-label text-xs font-normal mr-1">
              {valueData[key]}
            </span>{" "}
            <span className="legend-label text-xs font-normal">{key}</span>
          </div>
        ))}
      </div>
    );
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/dashboardPool`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `tbl=accounts`,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      setData(jsonData);

      console.log(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className="overflow-x-hidden flex flex-col mx-auto justify-start pt-9 bg-[#F8FBFF]">
      <div className="text-slate-500 font-medium text-base mx-4 mb-4">
        <span>Data Overview as on </span>
        <span className="text-slate-700 text-lg">{formattedDate}</span>
      </div>
      {tabledata ? (
        <div>
          <div className="w-[97%] mx-4">
            <TextComponent
              data={dashboardData[0]}
              val={{ "Total No. of Private Users": tabledata.total_users }}
            />
          </div>

          <div className="flex w-full h-1/3">
            <div className="w-[48%] ml-4 h-1/3">
              <DonutChartComponent
                data={dashboardData[1]}
                height={363}
                width={350}
                title={{ x: 15, y: 24 }}
                chart={{ x: -5, y: 65 }}
                val={tabledata.utypeCounts}
              />
            </div>
            <div className="w-[44%] ml-4 shadow-md rounded-2xl bg-white">
              <div className="pt-6 ml-6">
                <p className="text-[18px] font-semibold">
                  {dashboardData[2].data[0].name}
                </p>
              </div>
              {/* <BarChartComponent
                height={260}
                data={dashboardData[2]}
                chart={{ x: 0, y: 0 }}
                yaxisOptions={{min:0,max:50}}
                skipValue={5}
                val={tabledata.locationData.countryObj}
              /> */}
              {/* <WorldMapChart data={tabledata.locationData.data} /> */}
              <MapChart countryData={tabledata.locationData.newCountryObj} />
              
            </div>
          </div>


          <div className="flex mt-4">
            <div className="w-[53%] ml-4 h-1/3 shadow-md rounded-2xl bg-white">
              <div className="mt-6 ml-6">
                <p className="text-[18px] font-semibold">
                  {dashboardData[11].data[0].name}
                </p>
              </div>
              <BarChartComponent
                height={260}
                data={dashboardData[2]}
                chart={{ x: 0, y: -10 }}
                val={tabledata.locationData.stateObj}
                yaxisOptions={{min:100,max:1000}}
                skipValue={10}
              />
            </div>
            <div className="w-[39%] ml-4 h-1/3 shadow-md rounded-2xl bg-white">
              <div className="mt-6 ml-6">
                <p className="text-[18px] font-semibold">
                  {dashboardData[12].data[0].name}
                </p>
              </div>
              <BarChartComponent
                height={260}
                data={dashboardData[2]}
                chart={{ x: 0, y: -10 }}
                val={tabledata.locationData.tierObj}
                yaxisOptions={{min:500,max:10000}}
                skipValue={10}
              />
            </div>
          </div>

          <div className="w-[97%] mx-4 mt-4">
            <div className="shadow-md rounded-2xl bg-white overflow-hidden">
              <div className="mt-6 ml-6">
                <p className="text-[18px] font-semibold">
                  {dashboardData[10].data[0].name}
                </p>
              </div>
              <BarChartComponent
                height={260}
                data={dashboardData[10]}
                chart={{ x: 0, y: -20 }}
                val={tabledata.locationData.cityObj}
                yaxisOptions={{min:0,max:100}}
                skipValue={10}
              />
            </div>
          </div>

          <div className="flex w-full h-1/3 mt-4">
            <div className="w-[55%] ml-4 h-1/3 shadow-md rounded-2xl bg-white">
              <div className="mt-7 pl-7">
                <p className="text-[18px] font-semibold">
                  {dashboardData[3].data[0].name}
                </p>
              </div>
              <BarChartComponent
                height={280}
                data={dashboardData[3]}
                chart={{ x: 0, y: 10 }}
                val={tabledata.ageCount}
              />
            </div>
            <div className="w-[37%] ml-4 h-1/3 relative">
              <PieChartComponent
                data={dashboardData[4]}
                height={300}
                title={{ x: 10, y: 25 }}
                val={tabledata.gender}
              />
              <Legend />
            </div>
          </div>

          <div className="w-[95%] mx-4 mt-4">
            <TextComponent
              data={dashboardData[5]}
              val={{
                "Total No. of Data Points": tabledata.total_private,
              }}
            />
          </div>

          <div className="flex mt-4">
            <div className="w-[46%] ml-4 h-1/3">
              <DonutChartComponent
                data={dashboardData[6]}
                height={300}
                width={290}
                title={{ x: 15, y: 24 }}
                chart={{ x: 0, y: 60 }}
                val={tabledata.dataPoints}
              />
            </div>
            <div className="w-[46%] ml-4 h-1/3">
              <DonutChartComponent
                data={dashboardData[9]}
                height={300}
                width={310}
                title={{ x: 15, y: 24 }}
                chart={{ x: 0, y: 60 }}
                val={tabledata.total_data}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default DashBoardPage;
