import React from "react";
import { useNavigate } from "react-router-dom";

const DynamicTable = ({
  data,
  hiddenFields = [],
  clickableFields = [],
  bulletFields = {},
}) => {
  const navigate = useNavigate();

  if (!data || data.length === 0) {
    return <p>No data available.</p>;
  }

  const maxKeysObject = data.reduce((max, obj) =>
    Object.keys(obj).length > Object.keys(max).length ? obj : max
  );

  // Extract column names from the object with maximum keys
  const columns = Object.keys(maxKeysObject).filter(
    (column) => !hiddenFields.includes(column)
  );

  const handleFieldClick = (path, key, data, type) => {
    console.log("tableData", key, data);
    localStorage.setItem("type", JSON.stringify({ type }));
    console.log(type);
    localStorage.setItem(key, JSON.stringify(data));
    navigate(`/${path}`);
  };

  return (
    <div className="overflow-x-scroll scrollbar-thin m-4">
      <table className="w-full border-collapse shadow-md text-xs">
        <thead>
          <tr className="border-r">
            {columns.map((column, index) => (
              <th
                key={column}
                className={`border p-4 ${
                  index >= 0 ? "bg-blue-head text-neutral-800" : ""
                }`}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${rowIndex > 0 ? "border-t border-zinc-300" : ""}`}
            >
              {columns.map((column, colIndex) => (
                <td
                  key={column}
                  className={`py-2 px-4 ${
                    colIndex < columns.length - 1
                      ? "border-r border-zinc-300"
                      : ""
                  }`}
                >
                  {clickableFields.find((field) => field.name === column) ? (
                    <button
                      onClick={() =>
                        handleFieldClick(
                          clickableFields.find((field) => field.name === column)
                            .path,
                          clickableFields.find((field) => field.name === column)
                            .key,
                          data,
                          clickableFields.find((field) => field.name === column)
                            .type // Optional parameter is passed here
                        )
                      }
                      className="underline cursor-pointer text-[#F09933]"
                    >
                      {Array.isArray(row[column])
                        ? row[column].map((item, index) => (
                            <div key={index}>
                              {bulletFields[column] ? (
                                <li>{item}</li>
                              ) : (
                                <div
                                  className={`block ${
                                    index % 2 == 0
                                      ? "bg-[#D3EAFF]"
                                      : "bg-[#FBE7E9]"
                                  } text-xs text-[#222222] px-3 py-0.5 rounded mb-1.5`}
                                >
                                  {item}
                                </div>
                              )}
                            </div>
                          ))
                        : row[column]}
                    </button>
                  ) : Array.isArray(row[column]) ? (
                    row[column].map((item, index) => (
                      <div key={index}>
                        {bulletFields[column] ? (
                          <li>{item}</li>
                        ) : (
                          <div
                            className={`block ${
                              index % 2 == 0 ? "bg-[#D3EAFF]" : "bg-[#FBE7E9]"
                            } text-xs text-[#222222] px-3 py-0.5 rounded mb-1.5`}
                          >
                            {item}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
