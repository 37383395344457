// PasswordPopup.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PasswordPopup = () => {
  const [password, setPassword] = useState("");
  const correctPassword = "Faizan"; // Replace with your correct password
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const navigate=useNavigate()

  const handlePopupConfirm = () => {
    if (password === correctPassword) {
      navigate("/allusers");
      setPassword("");
    } else {
      setIncorrectPassword(true);
      setPassword("");
    }
  };

  const handlePopupClose = () => {
    setPassword("");
    setIncorrectPassword(false);
    navigate("/alltables")
  };

  return (
    <>
        <div className="fixed inset-0 flex justify-center items-center">
          <div className="bg-white p-16 rounded-md shadow-md">
            <p>Enter password to access Master User Table:</p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your Password"
              className="border border-gray-300 rounded-md px-4 pr-20 py-2 mt-4"
            />
            {incorrectPassword && (
              <p className="text-red-500 text-sm mt-2">Incorrect password. Please try again.</p>
            )}
            <div className="mt-8 flex justify-start">
              <button
                onClick={handlePopupConfirm}
                className="bg-blue-500 text-white px-8 py-2 rounded-md mr-4"
              >
                Submit
              </button>
              <button
                onClick={handlePopupClose}
                className="bg-gray-300 px-8 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
    </>
  );
};

export default PasswordPopup;
