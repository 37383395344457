import React from "react";

function Top() {
  return (
    <div>
      <div className="ml-10 mt-5">
        <p className="opacity-50 text-zinc-950 text-[11px] font-normal font-['Poppins'] leading-[11px] tracking-wide">
          OVERVIEW OF
        </p>
        <div>
          <span className="text-gray-500 text-lg font-medium font-['Poppins'] leading-[23px] tracking-wide">
            Rajashree Das’s Dashboard on{" "}
          </span>
          <span className="text-gray-500 text-lg font-bold font-['Poppins'] leading-[23px] tracking-wide">
            12 Dec 2023
          </span>
        </div>
      </div>
    </div>
  );
}

export default Top;
