import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import DynamicTable from "./Table";

const ClusterChart = ({ data }) => {
  const chartContainer = useRef(null);
  const [allCluster, setAllCluster] = useState({});
  const [partCluster, setPartCluster] = useState({});
  // console.log(data.children[0].name)
  function generateClickableFields(childName) {
    const clickableFields = [
      {
        name: "Cluster Avg Skill Score",
        path: "detail-ksa",
        key: "Cluster Name",
      },
      {
        name: "Cluster Avg Ind Score",
        path: "detail-ind",
        key: "Cluster Name",
      },
    ];

    if (childName === "Drawing" || childName === "Handwriting Performance") {
      clickableFields.forEach((field) => {
        if (
          field.name === "Cluster Avg Skill Score" ||
          field.name === "Cluster Avg Ind Score"
        ) {
          field.name = ""; // Emptying the name if condition is met
        }
      });
    }

    return clickableFields;
  }
  const calculateClusterAverages = (cluster) => {
    if (cluster && cluster.children) {
      let totalChildIndAvgScore = 0;
      let totalChildKsaAvgScore = 0;
      let totalProblemAreaScore = 0;

      cluster.children.forEach((child) => {
        totalChildIndAvgScore += +child.childIndAvgScore || 0;
        totalChildKsaAvgScore += +child.childKsaAvgScore || 0;
        totalProblemAreaScore += +child.problemAreaScore || 0;
      });

      const avgChildIndicatorScore =
        totalChildIndAvgScore / cluster.children.length;
      const avgKsaScore = totalChildKsaAvgScore / cluster.children.length;
      const overallAverageScore =
        totalProblemAreaScore / cluster.children.length;

      return {
        "Cluster No": cluster.children[0].Cluster,
        "Cluster Name": cluster.children[0].Address_Thread,
        "Cluster Age": cluster.children[0].childAge,
        "Total Users": cluster.children.length,
        "Cluster Average Score": parseFloat(overallAverageScore.toFixed(2)),
        "Cluster Avg Ind Score": parseFloat(avgChildIndicatorScore.toFixed(2)),
        "Cluster Avg Skill Score": parseFloat(avgKsaScore.toFixed(2)),
      };
    }
  };

  const calculateAllClustersAverages = (allClusterData) => {
    const result = [];
    if (allClusterData && allClusterData.children) {
      for (const cluster of allClusterData.children) {
        result.push(calculateClusterAverages(cluster));
      }
      return result;
    }
  };

  const allClusterAverages = calculateAllClustersAverages(allCluster);
  const partClusterAverageResult = calculateClusterAverages(partCluster);
  console.log(allClusterAverages)
  let partClusterAverage = [];
  if (partClusterAverageResult) {
    partClusterAverage = [partClusterAverageResult];
  }

  const createChart = async (data) => {
    const width = 658;
    const height = width;

    const color = d3
      .scaleLinear()
      .domain([0, 5])
      .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
      .interpolate(d3.interpolateHcl);

    const pack = (data) =>
      d3.pack().size([width, height]).padding(3)(
        d3
          .hierarchy(data)
          .sum((d) => (d.children ? 0 : 1))
          .sort((a, b) => (b.value || 0) - (a.value || 0))
      );
    const root = pack(data);

    const svg = d3
      .create("svg")
      .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .attr("width", width)
      .attr("height", height)
      .attr(
        "style",
        `max-width: 100%; height: auto; display: block; margin: 0 -14px; cursor: pointer;`
      );

    const node = svg
      .append("g")
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      .attr("fill", (d) => (d.children ? color(d.depth) : "white"))
      .attr("pointer-events", (d) => (!d.children ? "none" : null))
      .on("mouseover", function () {
        d3.select(this).attr("stroke", "#000");
      })
      .on("mouseout", function () {
        d3.select(this).attr("stroke", null);
      })
      .on("click", (event, d) => {
        if (d.depth === 2) {
          setAllCluster(d.data);
          setPartCluster({});
        } else if (d.depth === 3) {
          setPartCluster(d.data);
          setAllCluster({});
        }
        if (focus !== d) {
          zoom(event, d);
          event.stopPropagation();
        }
      });

    const label = svg
      .append("g")
      .style("font", "14px sans-serif")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .style("fill-opacity", (d) =>
        d.parent === root || d.depth === 2 ? 1 : 0
      ) // Show labels only for the initial focus node (second level)
      .style("display", (d) =>
        d.parent === root || d.depth === 2 ? "inline" : "none"
      ) // Show labels only for the initial focus node (second level))
      .text((d) => (d.depth >= 2 ? d.data.name : ""));

    svg.on("click", (event) => zoom(event, root.children[0]));
    let focus = root.children[0];
    let view;
    zoomTo([focus.x, focus.y, focus.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];

      view = v;

      label.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr(
        "transform",
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr("r", (d) => d.r * k);
    }

    function zoom(event, d) {
      const focus0 = focus;

      focus = d;

      const transition = svg
        .transition()
        .duration(event.altKey ? 7500 : 750)
        .tween("zoom", (d) => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return (t) => zoomTo(i(t));
        });

      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === "inline";
        })
        .transition(transition)
        .style("fill-opacity", (d) => (d.parent === focus ? 1 : 0))
        .on("start", function (d) {
          if (d.parent === focus) this.style.display = "inline";
        })
        .on("end", function (d) {
          if (d.parent !== focus) this.style.display = "none";
        });
    }
    chartContainer.current.innerHTML = "";
    chartContainer.current.appendChild(svg.node());
  };

  useEffect(() => {
    createChart(data);
  }, [data]);

  return (
    <div>
      <div ref={chartContainer} />
      {allClusterAverages && allClusterAverages.length !== 0 ? (
        <div className="w-full">
          <DynamicTable
            data={allClusterAverages.sort(
              (a, b) => a["Cluster No"] - b["Cluster No"]
            )}
            clickableFields={generateClickableFields(data.children[0].name)}
            hiddenFields={[]}
          />
        </div>
      ) : (
        ""
      )}
      {partClusterAverage.length !== 0 ? (
        <div className="w-full">
          <DynamicTable
            data={partClusterAverage}
            clickableFields={generateClickableFields(data.children[0].name)}
            hiddenFields={[]}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClusterChart;
