import React from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import { useNavigate } from "react-router-dom";

function DonutChartComponent(data) {
  const valueData = data.val;
  console.log(valueData);
  const { vtype2 } = data.data.data[0];
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(`/${location}`);
  };

  // Calculate total sum of data values
  function numberToShort(number) {
    // Array of suffixes for different magnitudes
    const suffixes = ["", "k", " Mn", "B", "T"];
  
    // Determine the magnitude (thousand, million, billion, etc.)
    const magnitude = Math.floor(Math.log10(number) / 3);
  
    // Divide the number by the magnitude and round to 1 decimal place
    const shortNum = parseFloat((number / Math.pow(1000, magnitude)).toFixed(1));
  
    // Append the suffix to the number
    return shortNum + suffixes[magnitude];
  }

  // Create legend items with percentage values
  const legendData = Object.keys(valueData).map((key) => {
    const value = valueData[key];
    const formattedValue = numberToShort(value);
    return {
      name: `${key}: ${formattedValue}`,
      color: data.data.data[0].colors[key],
    };
  });

  const options = {
    chart: {
      height: data.height,
      width: data.width,
      type: "donut", // Change chart type to donut
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      style: {
        fontSize: "12px",
        colors: ["#000000"],
      },
    },
    labels: Object.keys(valueData),
    legend: {
      show: false, // Disable ApexCharts legend
    },
    plotOptions: {
      pie: {
        offsetX: data.chart.x,
        offsetY: data.chart.y,
        donut: {
          size: "50%", // Adjust the width of the donut
          labels: {
            show:false,
            stroke: {
              width: 10, // Adjust the width of the stroke
              lineCap: "round",
            },
          },
        },
      },
    },
    title: {
      text: `${data.data.data[0].button ? "" : data.data.data[0].name}`,
      align: "left",
      offsetX: data.title.x,
      offsetY: data.title.y,
      floating: true,
      style: {
        fontSize: "18px",
        color: "#263238",
      },
    },
    colors: Object.values(data.data.data[0].colors),
  };

  // Define your chart data
  const series = Object.values(valueData);

  return (
    <div
      className={`border-black shadow-md rounded-2xl items-center bg-white flex`}
    >
      <div>
        {data.data.data[0].button && (
          <div className="px-5 pt-5">
            <p className="text-[18px] font-bold">{data.data.data[0].name}</p>
            {data.data.data[0].text &&
              Object.keys(data.data.data[0].text).length !== 0 && (
                <p className="mt-6">{data.data.data[0].text.text}</p>
              )}
            {data.data.data[0].button &&
              Object.keys(data.data.data[0].button).length !== 0 && (
                <button
                  onClick={() => handleClick(data.data.data[0].navigate)}
                  className="absolute top-5 right-6 bg-custom-orange text-white shadow-md rounded-md px-6 py-2"
                >
                  {data.data.data[0].button.text}
                </button>
              )}
          </div>
        )}
      </div>
      <div className="flex mr-5 justify-between relative">
        {Object.keys(data.data.data[0].data).length !== 0 && (
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={data.height ? data.height : 300}
            width={data.width}
          />
        )}
        {/* Render legend */}
        <div className="mt-28 ml-2  w-full">
          {legendData.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <span className="mr-3">
                <span
                  className="inline-block rounded-full"
                  style={{
                    backgroundColor: item.color,
                    width: "10px",
                    height: "10px",
                  }}
                ></span>
              </span>
              <span className="text-xs">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DonutChartComponent;
