import React, { useEffect, useState } from "react";
import HeatmapChart from "../components/HeatMapChart";
import Breadcrumb from "../components/Breadcrumb";

function KsaCorrelation() {
  const [heatmapData, setheatmapData] = useState(null);
  const [type,setType]=useState("")
  console.log(heatmapData);
  function convertData(data, fieldName) {
    const transformedData = data.map((entry) => {
      const correlated_array = entry[fieldName];
      const { "Skill Name": ksaName } = entry;
      const correlatedIndicators = correlated_array.reduce((acc, item) => {
        const key = Object.keys(item)[0]; // Assuming each item in Correlated_Indicators has only one key
        acc[key] = item[key];
        return acc;
      }, {});
      return {
        name: ksaName,
        ...correlatedIndicators,
      };
    });
    return transformedData;
  }
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("ksa_data"));
    const type = JSON.parse(localStorage.getItem("type")).type;
    if (type == "ksa-ind") {
      setheatmapData(convertData(data, "Correlated_Indicators"));
      setType("Indicator")
    } else {
      setheatmapData(convertData(data, "Correlated_KSAs"));
      setType("Skill")
    }
  }, []);
  const breadcrumbLinks = {
    curation: { label: "Curation Engine", color: "grey", link: "/curation" },
    problemArea: {
      label: "Problem Areas",
      color: "grey",
      link: "/problem-area",
    },
    detailInd: {
      label: "Ksas Detailed Overview",
      color: "grey",
      link: "/detail-ksa",
    },
    ksaCorr: {
      label: `Skill-${type} Correlation`,
      color: "black",
      link: "/ksa-correlation",
    },
    // Add more breadcrumb links as needed
  };
  return (
    <div className="ml-4 mt-4">
      <Breadcrumb breadcrumbLinks={breadcrumbLinks} currentPage="ksaCorr" />
      {heatmapData ? <HeatmapChart data={heatmapData} /> : ""}
    </div>
  );
}

export default KsaCorrelation;
