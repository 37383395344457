import * as React from "react"
const DropdownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={5}
    fill="none"
    {...props}
  >
    <path
      stroke="#FF8A00"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m1 4 2.859-2.859a.2.2 0 0 1 .282 0L7 4"
    />
  </svg>
)
export default DropdownArrow
