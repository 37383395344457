import React, { useEffect, useState } from "react";
import HeatmapChart from "../components/HeatMapChart";
import Breadcrumb from "../components/Breadcrumb";

function Indicatorcorrelation() {
  const [heatmapData, setheatmapData] = useState(null);
  const [type, setType] = useState("");
  console.log(heatmapData);
  function convertData(data, fieldName) {
    const transformedData = data.map((entry) => {
      const correlated_array = entry[fieldName];
      const { "Indicator Name": indicatorName } = entry;
      const correlatedIndicators = correlated_array.reduce((acc, item) => {
        const key = Object.keys(item)[0]; // Assuming each item in Correlated_Indicators has only one key
        acc[key] = item[key];
        return acc;
      }, {});
      return {
        name: indicatorName,
        ...correlatedIndicators,
      };
    });
    return transformedData;
  }
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("indicator_data"));
    const type = JSON.parse(localStorage.getItem("type")).type;
    if (type == "ind-ind") {
      setheatmapData(convertData(data, "Correlated_Indicators"));
      setType("Indicator");
    } else {
      setheatmapData(convertData(data, "Correlated_KSAs"));
      setType("Skills");
    }
  }, []);
  const breadcrumbLinks = {
    curation: { label: "Curation Engine", color: "grey", link: "/curation" },
    problemArea: {
      label: "Problem Areas",
      color: "grey",
      link: "/problem-area",
    },
    detailInd: {
      label: "Indicator Detailed Overview",
      color: "grey",
      link: "/detail-ind",
    },
    indCorr: {
      label: `Indicator-${type} Correlation`,
      color: "black",
      link: "/ind-correlation",
    },
    // Add more breadcrumb links as needed
  };
  return (
    <div className="ml-4 mt-4">
      {heatmapData ? (
        <>
          <Breadcrumb breadcrumbLinks={breadcrumbLinks} currentPage="indCorr" />
          <HeatmapChart data={heatmapData} />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Indicatorcorrelation;
