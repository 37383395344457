import React, { useEffect, useState } from "react";
import data from "../constant/curation.json";
import BarChartComponent from "../BarChartComponent";
import TextComponent from "../TextComponent";
import PieChartComponent from "../PieChartComponent";
import Loader from "../components/Loader";
import { API_URL, getDaySuffix } from "../constant";
import { useNavigate } from "react-router-dom";
import DonutChartComponent from "../components/DonutChart";
const CurationEngine = () => {
  const [tabledata, setData] = useState(null); // Initialize state with null
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const formattedDate = `${day}${getDaySuffix(day)} ${month} ${year}`;
  // console.log(tabledata, tabledata?.cityCount);
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(`/${location}`);
  };

  const valueData = tabledata?.genderCount;
  const Legend = () => {
    return (
      <div className="custom-legend flex space-x-10 absolute bottom-8 left-1/2 transform -translate-x-1/2">
        {Object.keys(valueData).map((key, index) => (
          <div key={index} className="legend-item flex items-center">
            <div className="legend-color" />
            <img
              className="legend-image mr-2"
              src={`/images/${key}.png`}
              alt={`legend-${index}`}
            />
            <span className="legend-label text-xs font-normal mr-1">
              {valueData[key]}
            </span>{" "}
            <span className="legend-label text-xs font-normal">{key}</span>
          </div>
        ))}
      </div>
    );
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/curation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `tbl=accounts`,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      setData(jsonData);
      console.log(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="overflow-x-hidden flex flex-col mx-auto flex-wrap pt-9 bg-[#F8FBFF]">
      <p className="text-slate-300 font-medium text-sm mx-4 mb-2">
        OVERVIEW OF
      </p>
      <div className="text-slate-500 font-medium text-base mx-4 mb-4">
        <span>Curation Engine Training Data as on </span>
        <span className="text-slate-700 text-lg">{formattedDate}</span>
      </div>
      {tabledata ? (
        <div>
          <div className="flex w-full h-1/3 mt-4">
            <div className="w-[35%] ml-4 h-1/3">
              <TextComponent
                data={data[0]}
                val={{
                  "Total Children in Training Data": tabledata.total_users,
                }}
              />
              <div className="ml-2 relative">
                <PieChartComponent
                  data={data[3]}
                  height={260}
                  val={tabledata.genderCount}
                  title={{ x: 10, y: 25 }}
                />
                <Legend />
              </div>
            </div>
            <div className="w-[60%] ml-4 h-1/3  mr-4 bg-white shadow-md rounded-2xl">
              <div className="mt-6 ml-7">
                <p className="text-[18px] font-semibold">
                  {data[4].data[0].name}
                </p>
              </div>
              <BarChartComponent
                data={data[4]}
                height={350}
                val={tabledata.ageCount}
              />
            </div>
          </div>
          <div className="flex w-full h-1/3 mt-4">
            <div className="w-[47%] ml-4 h-1/3  mr-4 bg-white shadow-md rounded-2xl">
              <div className="mt-6 ml-7">
                <p className="text-[18px] pt-1 font-semibold">
                  {data[5].data[0].name}
                </p>
              </div>
              <BarChartComponent
                data={data[5]}
                height={235}
                y={5}
                horizontal={true}
                val={{
                  "3 to 4 moths": 18155,
                  "5 to 6 months": 5950,
                  "7 to 12 months": 2563,
                  "13 to 24 months": 453,
                }}
              />
            </div>
            <div className="w-[47%] ml-2 h-1/3">
              <DonutChartComponent
                data={data[6]}
                height={280}
                width={270}
                title={{ x: 15, y: 24 }}
                chart={{ x: 5, y: 60 }}
                val={tabledata.result}
              />
            </div>
          </div>
          <div className="flex w-full h-1/3 mt-4">
            <div className="w-[40%] ml-4 h-1/3">
              <div className="bg-white shadow-md rounded-2xl overflow-hidden">
                <div className="mt-2 ml-6">
                  <p className="text-[18px] pt-6 pl-[8px] font-semibold">
                    {data[1].data[0].name}
                  </p>
                </div>
                <BarChartComponent
                  data={data[1]}
                  height={335}
                  val={tabledata.cityCount}
                  yaxisOptions={{ min: 0, max: 1000 }}
                  skipValue={10}
                />
              </div>
            </div>
            <div className="w-[55%] ml-4 h-1/3  mr-4 bg-white shadow-md rounded-2xl space-y-[-10px]">
              <div className="mb-6 pt-6 px-6 relative overflow-hidden">
                <p className="text-[18px] font-semibold">
                  {data[2].data[0].name}
                </p>
                <p className="mt-6 text-xs">{data[2].data[0].text.text}</p>
                <button
                  onClick={() => handleClick(data[2].data[0].navigate)}
                  className="absolute top-5 right-6 ml-4 bg-custom-orange text-white shadow-md rounded-md px-6 py-2"
                >
                  {data[2].data[0].button.text}
                </button>
              </div>
              <div className="h-80 pt-[-250px] overflow-y-auto scrollbar-thin overflow-x-hidden">
                <BarChartComponent
                  data={data[2]}
                  height={Object.keys(tabledata.problemAreaCounts).length*50}
                  chart={{ x: 0, y: 0 }}
                  val={tabledata.problemAreaCounts}
                  tooltip={true}
                  horizontal={true}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CurationEngine;
