import React, { useEffect, useState } from "react";
import DynamicTable from "../Table";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { API_URL } from "../constant";
import SearchBoxIcon from "../components/SearchBoxIcon";
import DropDownComponent from "../components/DropDownComponent";
import Breadcrumb from "../components/Breadcrumb";

function ActivitesCmp() {
  const [listOfDropdown, setListOfDropdown] = useState([
    { label: "All", subOptions: ["All"] },
    { label: "Age", subOptions: ["All"] },
    { label: "Level_No", subOptions: ["All"] },
  ]);
  const [tableData, setTableData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [prev, setprev] = useState("");
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [searchActive, setSearchActive] = useState(true);
  const [activestate, setactive] = useState("");
  const [activesubstate, setactivesub] = useState("");
  const [searchFilter, setSearchFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (searchQuery) {
        handleSearch();
        return;
      }
      const response = await fetch(`${API_URL}/api/getallactivites`);
      const jsonData = await response.json();
      setTableData(jsonData.allactivities);
      setTotalUsers(jsonData.totalCount);
      setActivityData(jsonData.allactivities);
      const ageSet = new Set();
      const levelNoSet = new Set();

      jsonData.allactivities.forEach((item) => {
        const ages = item.Age.split(",");
        ages.forEach((age) => ageSet.add(age.trim()));

        levelNoSet.add(item.Level_No.trim());
      });
      setIsLoading(false);
      // Filter out existing labels before adding new ones
      const filteredDropdown = listOfDropdown.filter(
        (dropdown) => dropdown.label !== "Age" && dropdown.label !== "Level_No"
      );

      // Convert sets to arrays and update the state
      setListOfDropdown([
        ...filteredDropdown,
        { label: "Age", subOptions: ["All", ...ageSet] },
        { label: "Level_No", subOptions: ["All", ...levelNoSet] },
      ]);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  // console.log(listOfDropdown, "listdrop")
  useEffect(() => {
    // console.log(searchFilter,"search")
    // if (!searchFilter) {
    fetchData();
    // console.log("no active",currentPage)
    // }
  }, []);
  useEffect(() => {
    if (activestate || activesubstate) {
      // fetchData();
      if (!searchActive) {
        handleFilterapi();
      }
    }
  }, [activestate, activesubstate, currentPage]);
  useEffect(() => {
    if (currentPage && searchActive && searchQuery !== "") {
      handleSearch();
    }
  }, [currentPage, searchActive]);
  const handleActiveSubarrayChange = async (active, newActiveSubarray) => {
    if (active && newActiveSubarray) {
      setactive(active);
      setactivesub((prevState) => newActiveSubarray);
    }
    setIsSearchBtn(false);
    setSearchActive(false);
    setSearchFilter(true);
    // handleFilterapi();
    setSearchQuery("");
    // Check if both active and newActiveSubarray are defined and not "All"
  };
  const handleFilterapi = async () => {
    setIsLoading(true);
    if (
      activestate &&
      activesubstate &&
      activestate !== "All" &&
      activesubstate !== "All"
    ) {
      if (activesubstate !== prev) {
        setprev(activesubstate);
        setCurrentPage(1);
      }
      // console.log(activityData, "substate")
      let filteredData = [];
      if (activestate === "Age") {
        filteredData = activityData.filter((item) => {
          // console.log("asdsa", item)
          // Check if the item meets the criteria
          return item.Age.includes(activesubstate);
        });
      } else if (activestate === "Level_No")
        filteredData = activityData.filter((item) => {
          // Check if the item meets the criteria
          return item.Level_No === activesubstate;
        });
      else {
        filteredData.push(...activityData);
      }
      setTableData(filteredData);
      setIsLoading(false);
    } else {
      // Reset pagination and fetch all users
      setCurrentPage(1);
      setIsLoading(false);
      setprev("");
      fetchData();
    }
  };
  const handleSearch = () => {
    setIsSearchBtn(!isSearchBtn);
    setSearchActive(true);
    setSearchFilter(true);
    setIsLoading(true);
    const isSearchQueryEmpty = searchQuery.trim() === "";
    if (isSearchQueryEmpty) {
      setCurrentPage(1);
      setprev("");
      fetchData();
      return;
    }

    if (searchQuery !== prev) {
      setCurrentPage(1);
      setprev(searchQuery);
    }

    const filteredData = activityData.filter((item) => {
      // Exclude 'Age' and 'Level_No' fields from search
      const { Age, Level_No, ...rest } = item;
      for (const key in rest) {
        const value = String(item[key]).toLowerCase();
        if (value.includes(searchQuery.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    setTableData(filteredData);
    setIsLoading(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const currentPageData = tableData.slice(startIndex, endIndex);
  const breadcrumbLinks = {
    rawTables: { label: "Raw Tables", color: "grey", link: "/alltables" },
    activityTable: {
      label: "Activity Table",
      color: "black",
      link: "/allactivity",
    },
  };
  return (
    <div>
      <div className="mt-4">
        <Breadcrumb
          breadcrumbLinks={breadcrumbLinks}
          currentPage="activityTable"
        />
      </div>
      <div className="text-slate-700 font-medium text-base mx-4 mt-2">
        <span>Activity Table</span>
        <span className="text-slate-800 text-lg font-bold"></span>
      </div>

      {currentPageData?.length !== 0 ? (
        <div>
          <div className="flex flex-col bg-white shadow-md rounded-md m-4">
            <p className="pt-4 pb-3 text-[#5F6679] text-sm font-semibold ml-4">
              What are you looking for?
            </p>
            <div className="flex items-center justify-start mb-5 ml-4">
              <div className="rounded  flex items-center relative w-2/6">
                <input
                  placeholder="Search for Age..."
                  className=" bg-[#F6F6FB] py-2 pl-4 border-none outline-none text-xs text-[#1F384C]  w-full rounded"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onMouseEnter={() => setShowLabel(true)}
                  onMouseLeave={() => setShowLabel(false)}
                />
                <SearchBoxIcon className="absolute right-2 top-2" />
                {showLabel && searchQuery === "" && (
                  <label className="absolute left-4 top-10 text-white pl-4 py-2 font-normal text-xs pointer-events-none transition-opacity opacity-100 leading-2 w-fit rounded-md bg-black text-clip">
                    Search for Key Name Level_No level_score F_Target
                    F_target_value I_Target I_Target_Value p_value S_Target
                    S_Target_Value T Age Objective dos donts instructions
                    act_mongo_ID
                  </label>
                )}
              </div>
              <div className="flex  ml-8">
                <DropDownComponent
                  Heading="Filter By"
                  options={listOfDropdown}
                  searchBtn={isSearchBtn}
                  onActiveSubarrayChange={handleActiveSubarrayChange} // Pass the callback function
                />
                <button
                  className="ml-4 bg-[#F09933] text-white rounded py-[6px] pl-6 pr-7 text-xs font-medium h-8"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {!isLoading ? (
            <>
              <div>
                <DynamicTable data={currentPageData} />
              </div>
              <div className="flex overflow-x-auto sm:justify-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ActivitesCmp;
