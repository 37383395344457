import React from "react";
import WorldMap from "react-svg-worldmap";

function WorldMapChart(data) {
  // console.log(data)
  // const data = [
  //   { country: "sg", value: 138961877800000 }, // china
  //   { country: "ae", value: 131155920400 }, // india
  //   { country: "us", value: 331883986 }, // united states
  //   // { country: "id", value: 264935824 }, // indonesia
  //   // { country: "pk", value: 210797836 }, // pakistan
  //   // { country: "be", value: 3103015913 }, // brazil
  //   // { country: "ng", value: 208679114 }, // nigeria
  //   // { country: "bd", value: 161062905 }, // bangladesh
  //   // { country: "ru", value: 141944641 }, // russia
  //   // { country: "mx", value: 127318112 }, // mexico
  // ];
  return (
    <div className="px-2">
      <WorldMap
        color="blue"
        // title="Top 10 Populous Countries"
        value-suffix="people"
        size="lg"
        // height={300}
        // style={{ height: "100%" }}s
        data={data.data}
      />
    </div>
  );
}

export default WorldMapChart;
