// Breadcrumb.js
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbLinks, currentPage }) => {
  return (
    <nav className="p-4">
      <ol className="list-none p-0 inline-flex">
        {Object.entries(breadcrumbLinks).map(([key, value]) => {
          const isCurrentPage = key === currentPage;
          return (
            <li key={key} className="flex items-center">
              {isCurrentPage ? (
                <span style={{ color: value.color, cursor: "pointer", fontSize: "13px" }}>{value.label}</span>
              ) : (
                <Link to={value.link} style={{ color: value.color, fontSize: "13px" }}>
                  {value.label}
                </Link>
              )}
              {!isCurrentPage && <span className="mx-2">{'>'}</span>}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
