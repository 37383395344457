import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router,Routes,Route, BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import DashBoardPage from './pages/dashboardpage';
import CurationEngine from './pages/curation';
import Age from './pages/age';
import PrivatePage from './pages/Private';
import ProblemArea from './pages/ProblemArea';
import DetailInd from './pages/DetailInd';
import DetailKsa from './pages/DetailKsa';
import HeatmapChart from './components/HeatMapChart';
import Indicatorcorrelation from './pages/Indicatorcorrelation';
import KsaCorrelation from './pages/Ksacorrelation';
import Allusers from './pages/Allusers';
import AssessmentReport from './pages/Assessment-Report';
import Folder from './pages/RawTableList';
import ActivitesCmp from './pages/ActivityTable';
import IndicatorAgeCmp from './pages/IndicatorAgeTable';
import ClusterTable from './pages/ClusterTable';
import AllIndKsa from './pages/IndicatorKsa';
import AllIndQues from './pages/IndQuestion';
import AllIndAct from './pages/IndicatorActivity';
import AllIndKsaCsv from './pages/IndKsaCsv';
import AllIndQuesCsv from './pages/IndQuesCsv';
import PasswordPopup from './components/Password';
import DrawingReport from './pages/DrawingReport';
import HandwritingQue from './pages/Handwritingque';
import HandwritingImg from './pages/Handwritinimg';
import MapChart from './components/Map';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path='/' element={<DashBoardPage />}></Route>
        <Route exact path='/curation' element={<CurationEngine />}></Route>
        <Route exact path='/age' element={<Age />}></Route>
        <Route exact path='/private' element={<PrivatePage />}></Route>
        <Route exact path='/problem-area' element={<ProblemArea />}></Route>
        <Route exact path='/detail-ind' element={<DetailInd />}></Route>
        <Route exact path='/detail-ksa' element={<DetailKsa />}></Route>
        <Route exact path='/ind-correlation' element={<Indicatorcorrelation/>}></Route>
        <Route exact path='/ksa-correlation' element={<KsaCorrelation/>}></Route>
        <Route exact path='/alltables' element={<Folder/>}></Route>
        <Route exact path='/allusers' element={<Allusers/>}></Route>
        <Route exact path='/allassessment' element={<AssessmentReport/>}></Route>
        <Route exact path='/allactivity' element={<ActivitesCmp/>}></Route>
        <Route exact path='/indicatorAge' element={<IndicatorAgeCmp/>}></Route>
        <Route exact path='/clustertable' element={<ClusterTable/>}></Route>
        <Route exact path='/indKsatable' element={<AllIndKsaCsv/>}></Route>
        <Route exact path='/indQuestable' element={<AllIndQuesCsv/>}></Route>
        <Route exact path='/indactivity' element={<AllIndAct/>}></Route>
        <Route exact path='/checkpassword' element={<PasswordPopup/>}></Route>
        <Route exact path='/allDrawing' element={<DrawingReport/>}></Route>
        <Route exact path='/handwritingques' element={<HandwritingQue/>}></Route>
        <Route exact path='/handwritingimg' element={<HandwritingImg/>}></Route>
        <Route exact path='/map' element={<MapChart/>}></Route>
      </Routes>
    </Router>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
