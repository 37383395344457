import React, { useState } from "react";
import IndicatorView from "./IndicatorView";
import SkillsView from "./SkillsView";


function PrivatePage() {
  const navNames = [
    "Indicator View",
    "Indicator Correlation",
    "Skills Overview",
    "Skill Correlation",
    "Indicator-Skill Correlation",
  ];

  const [selectedPage, setSelectedPage] = React.useState(0); // Set default value to 0

  const handleNavClick = (index) => {
    setSelectedPage(index);
  };

  const renderContent = () => {
    // Based on the selected page, render the corresponding content
    switch (selectedPage) {
      case 0:
        return <IndicatorView />;
      case 1:
        return <div>Content for Indicator Correlation</div>;
      case 2:
        return <SkillsView />;
      case 3:
        return <div>Content for Skill Correlation</div>;
      case 4:
        return <div>Content for Indicator-Skill Correlation</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex justify-around px-6 pt-6  border-b">
        {navNames.map((name, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              backgroundColor: index === selectedPage ? "#F09933" : "#FFFAF1",
            }}
            className="px-4 py-2 rounded-t-md "
            onClick={() => handleNavClick(index)}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: index === selectedPage ? "#FFFAF1" : "#F09933",
              }}
              className="rounded-md"
            ></div>
            <span
              style={{
                marginLeft: "10px",
                color: index === selectedPage ? "white" : "#A8A7A8",
              }}
              className="text-xs font-medium"
            >
              {name}
            </span>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "20px" }}>{renderContent()}</div>
    </div>
  );
}

export default PrivatePage;
